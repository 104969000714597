import React from 'react';
import {ButtonContainer, ButtonTextContainer, ButtonText} from '../styles';
import shareIcon from '../../../../../assets/share-icon.svg';

const ShareButton = ({ onClick }) => {
  return (
    <ButtonContainer maxWidth={150} onClick={onClick}>
      <img src={shareIcon} height={16} width={16} alt={'Share device'} />
      <ButtonTextContainer>
        <ButtonText color={'white'} size={12}>
          Share
        </ButtonText>
      </ButtonTextContainer>
    </ButtonContainer>
  );
};

export default ShareButton;
