import React from 'react';
import PropTypes from 'prop-types';
import { Text, Box, Link as SambaLink, Heading } from 'samba-ui';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const AuthError = () => {
  return (
    <Box mt='72px' textAlign='center'>
      <Heading fontSize={5} color='white'>
        Your access is denied!
      </Heading>
      <Box>
        <Text color='white' fontSize={2}>
          This feature is just for Samba TV employees. Make sure you are trying to login with Samba Gmail account!
        </Text>
      </Box>
      <Box my={3}>
        <StyledLink to='/'>
          <SambaLink fontSize={2} fontWeight={500}>
            Go back to sign-in page
          </SambaLink>
        </StyledLink>
      </Box>
    </Box>
  );
};

AuthError.propTypes = {
  error: PropTypes.string,
};

export default AuthError;
