import React from 'react';
import Styled from 'styled-components';

import { DEVICE_BOX_HEIGHT } from './constants';
import { containerDarkBackground, containerLightBackground } from '../../../utils/cssConstants';

const DeviceContainer = Styled.div`
  padding: 10px 10px 10px 30px;
  margin: 0 30px 0 0;
  background-color: ${(props) => (props.isSelected ? containerLightBackground : containerDarkBackground)};
  border-radius: 5px;
  min-height: ${DEVICE_BOX_HEIGHT}px;
  min-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
  align-items: ${(props) => (props.center ? 'center' : 'flex-start')};
`;

const Device = ({ center = false, children, isSelected = false, onClick }) => {
  return (
    <DeviceContainer isSelected={isSelected} onClick={onClick} center={center}>
      {children}
    </DeviceContainer>
  );
};

export default Device;
