import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Flex, Text } from 'samba-ui';

const ChangePasswordSuccess = () => {
  return (
    <Box mt='72px'>
      <Heading fontSize={5} color='white'>
        Help is on the way. Check your email
      </Heading>
      <Flex alignItems='center' justifyContent='center' mt={3}>
        <Text fontSize={1} color='white'>
          Please check your email to reset your password.
        </Text>
      </Flex>
    </Box>
  );
};

ChangePasswordSuccess.propTypes = {
  timeout: PropTypes.number,
};

export default ChangePasswordSuccess;
