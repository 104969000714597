import { getDevices, getDeviceContentState, getDeviceInfo } from './api';
import {
  getDevices as getDevicesStub,
  getDeviceInfo as getDeviceInfoStub,
  getDeviceContentState as getDeviceContentStateStub,
} from './stub';
import { getUseStubData } from '../config';

export default function getApiServices() {
  if (getUseStubData()) {
    return {
      getDevices: getDevicesStub,
      getDeviceContentState: getDeviceContentStateStub,
      getDeviceInfo: getDeviceInfoStub,
    };
  }

  return {
    getDevices,
    getDeviceContentState,
    getDeviceInfo,
  };
}
