export default {
  count: 8,
  devices: [
    {
      access_type: '',
      description: 'FFMPEG',
      guid: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
      make: 'Sony',
      model: '',
      model_id: 'Sony',
      flingo_make: 'sony',
      name: '',
      platform: 'ffmpeg',
      services: [
        {
          capability: [],
          description: 'Identifies content currently being viewed on the TV.',
          enabled: true,
          name: 'FLINGO Content ID',
          private_ip: ['10.0.0.243'],
          service: 'cid',
          t: 1619461612,
          version: '',
        },
      ],
      t: 1619461612,
      type: 'device',
    },
    {
      access_type: '',
      description: 'FFMPEG',
      external_access: [
        {
          external_ip: '67.169.91.80',
          expires_at: 1626814764,
          ttl_seconds: 1200,
        },
      ],
      guid: '7b9284d618bed423c8ae847076ed9ddce1d762a2',
      make: 'FFMPEG',
      model: '',
      model_id: 'FFMPEG',
      flingo_make: 'sony',
      name: '',
      platform: 'ffmpeg',
      services: [
        {
          capability: [],
          description: 'Identifies content currently being viewed on the TV.',
          enabled: true,
          name: 'FLINGO Content ID',
          private_ip: ['10.0.0.243'],
          service: 'cid',
          t: 1619461612,
          version: '',
        },
      ],
      t: 1619461612,
      type: 'device',
    },
    {
      access_type: '',
      description: 'webOS6.0',
      external_access: [
        {
          external_ip: '127.169.91.80',
          expires_at: 1626814764,
          expirationText: '9:00am',
          ttl_seconds: 1200,
        },
        {
          external_ip: '67.169.91.79',
          expires_at: 1626814764,
          expirationText: '9:01am',
          ttl_seconds: 1200,
        },
        {
          external_ip: '127.169.91.82',
          expires_at: 1626814764,
          expirationText: '9:02am',
          ttl_seconds: 1200,
        },
        {
          external_ip: '127.0.91.79',
          expires_at: 1626814764,
          expirationText: '9:03am',
          ttl_seconds: 1200,
        },
      ],
      guid: '53124292c30c6dd084a8306089de0ae706648499',
      make: 'LG',
      model: '',
      model_id: 'WEBOS6.0',
      flingo_make: 'sony',
      name: '',
      platform: 'webos6_lg',
      services: [
        {
          capability: [],
          description: 'Identifies content currently being viewed on the TV.',
          enabled: true,
          name: 'FLINGO Content ID',
          private_ip: ['10.0.0.51'],
          service: 'cid',
          t: 1619461737,
          version: '',
        },
      ],
      t: 1619461737,
      type: 'device',
    },
    {
      access_type: '',
      description: 'VESTEL',
      guid: '2be6cfa9666321bfefefe6a6f81efab854002690',
      make: 'VESTEL',
      model: '',
      model_id: 'VESTEL',
      flingo_make: 'VESTEL',
      name: 'VESTEL TV',
      platform: 'linux_novatek_nvt72671',
      services: [
        {
          capability: [],
          description: 'Identifies content currently being viewed on the TV.',
          enabled: true,
          name: 'FLINGO Content ID',
          private_ip: ['10.0.0.61'],
          service: 'cid',
          t: 1619461926,
          version: '',
        },
      ],
      external_access: [
        {
          external_ip: '127.169.91.80',
          expires_at: 1626814764,
          expirationText: '9:00am',
          ttl_seconds: 1200,
        },
      ],
      t: 1619461926,
      type: 'device',
    },
    {
      access_type: '',
      description: 'webOS5.2',
      guid: '5cdf630bf0e25e092c94f4c42d8b094004b1304f',
      make: 'LG',
      model: '',
      model_id: '55UN71006LB',
      flingo_make: 'LG',
      name: '',
      platform: 'webos5_lg',
      services: [
        {
          capability: [],
          description: 'Identifies content currently being viewed on the TV.',
          enabled: true,
          name: 'FLINGO Content ID',
          private_ip: ['10.0.0.44'],
          service: 'cid',
          t: 1619461419,
          version: '',
        },
      ],
      t: 1619461419,
      type: 'device',
    },
    {
      access_type: '',
      description: '',
      guid: '2c239abbe9a82369a6f26944af86cbd20c3b6aa4',
      make: 'VESTEL',
      flingo_make: 'VESTEL',
      model: '',
      model_id: '',
      name: '',
      platform: 'linux_mtk_mt9288',
      services: [
        {
          capability: [],
          description: 'Identifies content currently being viewed on the TV.',
          enabled: true,
          name: 'FLINGO Content ID',
          private_ip: ['10.0.0.135'],
          service: 'cid',
          t: 1619462064,
          version: '',
        },
      ],
      t: 1619462064,
      type: 'device',
    },
    {
      access_type: '',
      description: 'BeyondTV_rt51_Android9',
      guid: 'e51efd66679e08b870e79c22948cc86fc8afda12',
      make: 'TCL',
      model: '',
      flingo_make: 'TCL',
      model_id: '55P8S',
      name: 'BeyondTV',
      platform: 'android_rtk_rt51',
      services: [
        {
          capability: [],
          description: 'Identifies content currently being viewed on the TV.',
          enabled: true,
          name: 'FLINGO Content ID',
          private_ip: ['10.0.0.208'],
          service: 'cid',
          t: 1619461950,
          version: '',
        },
      ],
      t: 1619461951,
      type: 'device',
    },
    {
      access_type: '',
      description: 'Panasonic 2021 40" 4K TV',
      guid: '65314aba2b07f6e32844af80bad4a44388aa80cf',
      make: 'Panasonic',
      model: '',
      flingo_make: 'Panasonic',
      model_id: '19-12-19-03000140-00000200',
      name: '40JX800_Series',
      platform: 'linux_mtk_mt5816',
      services: [
        {
          capability: [],
          description: 'Identifies content currently being viewed on the TV.',
          enabled: true,
          name: 'FLINGO Content ID',
          private_ip: ['10.0.0.70'],
          service: 'cid',
          t: 1619462075,
          version: '',
        },
      ],
      t: 1619462075,
      type: 'device',
    },
    {
      access_type: '',
      description: '',
      guid: '1e21f1361053a2026eaee71745a1913022e420b4',
      make: 'VESTEL',
      flingo_make: 'VESTEL',
      model: '',
      model_id: '',
      name: '',
      platform: 'linux_mtk_g10',
      external_access: [
        {
          "external_ip":"67.169.91.80",
          "expires_at": 1629532792,
          "ttl_seconds": 14400
        },
      ],
      services: [
        {
          capability: [],
          description: 'Identifies content currently being viewed on the TV.',
          enabled: true,
          name: 'FLINGO Content ID',
          private_ip: ['10.0.0.169'],
          service: 'cid',
          t: 1619462081,
          version: '',
        },
      ],
      t: 1619462081,
      type: 'device',
    },
  ],
  interval: 900,
  yourip: '67.169.91.80',
};
