import get from 'lodash/get';
import has from 'lodash/has';
import find from 'lodash/find';
import isNil from 'lodash/isNil';

import { ORDERED_IMAGE_TYPES } from './constants';
import DEFAULT_IMAGE from '../assets/default-screenshot.png';

export const sanitizeUrl = (url = '') => encodeURI(url.replace(/'/g, "\\'"));

export const getContentImage = (content) => {
  // If the content already has an image, we can use that
  const imageType = find(
    ORDERED_IMAGE_TYPES,
    (imageType) => has(content, imageType) && !isNil(get(content, imageType)),
  );
  if (imageType) {
    return sanitizeUrl(content[imageType]);
  }

  // Otherwise, hopefully it is a series and we can grab from there
  if (has(content, 'series')) {
    const imageType = find(
      ORDERED_IMAGE_TYPES,
      (imageType) => has(content.series, imageType) && get(content.series, imageType),
    );
    if (imageType) {
      return sanitizeUrl(content.series[imageType]);
    }
  }

  return DEFAULT_IMAGE;
};
