import React from 'react';
import Styled from 'styled-components';
import { UppercaseText } from '../../../components/Text';
import refresh from '../../../assets/refresh.svg';
import RefreshingIcon from './RefreshingIcon';

const SelectionsList = Styled.ul`
  display: flex;
  flex-direction: row;
  margin-block-start: 0;
  margin-block-end: 0;
`;

const RefreshType = Styled.li`
  padding: 0 10px;  
  list-style: none;
  font-weight: ${(props) => (props.isSelected ? 'bold' : 'normal')};
  color: ${(props) => (props.isSelected ? 'white' : 'gray')};
  cursor: pointer;
`;

const RefreshButton = Styled.li`
  padding: 0 10px;  
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const RefreshDuration = ({ duration, selectedDuration, onClick }) => {
  return (
    <RefreshType isSelected={duration === selectedDuration} onClick={() => onClick(duration)}>
      <UppercaseText size={18}>{duration}</UppercaseText>
    </RefreshType>
  );
};

const RefreshSelection = ({ loading, refreshInterval, onRefresh, onClick }) => {
  return (
    <SelectionsList>
      {loading ? (
        <RefreshingIcon />
      ) : (
        <RefreshButton onClick={onRefresh}>
          <img src={refresh} height={20} width={20} alt='Refresh content state' />
        </RefreshButton>
      )}
      <RefreshDuration duration={10} selectedDuration={refreshInterval} onClick={onClick} />
      <RefreshDuration duration={20} selectedDuration={refreshInterval} onClick={onClick} />
      <RefreshDuration duration={30} selectedDuration={refreshInterval} onClick={onClick} />
      <RefreshDuration duration={'off'} selectedDuration={refreshInterval} onClick={onClick} />
    </SelectionsList>
  );
};

export default RefreshSelection;
