export default {
  guid: '7b9284d618bed423c8ae847076ed9ddce1d762a2',
  firmware_version: 'FFMPEG',
  make: 'FFMPEG',
  flingo_make: 'generic',
  model_id: 'FFMPEG',
  platform: 'ffmpeg',
  version: 'v3.1.0-Generic_ffmpeg_1.0',
  geo: 'Country: USA',
  df_algo: 'luma_40_t50',
  fpdbs: {
    bc: '10.1.2.60:11241',
    eg: '10.1.2.65:11210',
    lc: '10.1.2.49:11251',
  },
  cidserv_host: '3.2.2',
  ip: '67.169.91.80',
  private_ip: '10.0.0.243',
  name: '',
  description: 'FFMPEG',
};
