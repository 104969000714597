export const AUTH_APPLICATION_NAME = 'rt-cid-tv-integrations-webpage';
export const APPLICATION_NAME = 'TV Integrations Test Page';

export const DEFAULT_ERROR_MESSAGE = 'Somethings wrong. Please try again later';

export const ORDERED_IMAGE_TYPES = ['banner_image', 'poster_image', 'normal_image', 'boxart_image'];

export const CONTENT_TYPES = {
  ADVERTISEMENT: 'ADVERTISEMENT',
};

export const MAX_SHARED_DEVICES = 4;
