import Styled, { css } from 'styled-components';
import { containerDarkBackground } from '../../../../utils/cssConstants';
import { StyledText } from '../../../../components/Text';

const buttonBaseStyles = css`
  background-color: ${containerDarkBackground};
  border-radius: 8px;
  padding: 0 10px 0 5px;
  display: flex;
  align-items: center;
  border: 0;
`;

export const ButtonContainer = Styled.button`
  ${buttonBaseStyles}
  flex-grow: 0;
  max-width:  ${(props) => `${props.maxWidth || 250}px`};
  margin-right: 10px;
  text-align: center;
  justify-content: center;
  cursor: ${(props) => props.disabled ? 'inherit' : 'pointer'};
`;

export const ButtonTextContainer = Styled.div`
  padding-left: 15px;
`;

export const ButtonText = Styled(StyledText)`
  margin: 5px 5px;
`;
