import _ from 'lodash';
import React, { useContext, useMemo } from 'react';

import Image from './Image';
import Tag from './Tag';
import { CardContainer, ImageContainer, ContentContainer, TagsContainer } from './styles';
import { StyledText } from '../../../../components/Text';
import { MetadataApiContext } from '../../../../utils/contexts/metadata/MetadataApiContext';
import { getContentImage } from '../../../../utils/images';

const GeneralCard = ({ content }) => {
  const { genresById } = useContext(MetadataApiContext);

  const programDetails = useMemo(() => {
    if (!content) {
      return {
        releaseDate: '',
        genres: [],
        title: '',
        description: '',
        imageUrl: '',
      };
    }

    const releaseDate = _.get(content, 'release_date', '');
    const [year, month, date] = releaseDate ? releaseDate.split('-') : [];
    const genres = _.get(content, 'genres', []);
    const title = _.get(content, 'title', '');
    const description = _.get(content, 'description', '');
    const imageUrl = getContentImage(content);

    return {
      releaseDate: year && month && date ? `(${month}/${date}/${year})` : '',
      genres,
      title,
      description,
      imageUrl,
    };
  }, [content]);

  return (
    <CardContainer>
      <ImageContainer>
        <Image source={programDetails.imageUrl} />
      </ImageContainer>
      <ContentContainer>
        <StyledText size={18}>
          {programDetails.title} {programDetails.episodeText} {programDetails.releaseDate}
        </StyledText>
        <TagsContainer>
          {programDetails.genres.map((genre) => {
            return _.has(genresById, genre) ? <Tag label={genresById[genre].name} /> : null;
          })}
        </TagsContainer>
        <p>{_.get(programDetails, 'description', '')}</p>
      </ContentContainer>
    </CardContainer>
  );
};

export default GeneralCard;
