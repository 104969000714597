import get from 'lodash/get';
import Styled from 'styled-components';

export const UppercaseText = Styled.p`
  font-family: 'Fira Sans', 'Open Sans', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: ${(props) => props.size || 12}px;
  margin-block-start: 1em;
  margin-block-end: ${(props) => (get(props, 'marginBottom', true) ? '1em' : '0')};
`;

export const StyledText = Styled.p`
  font-family: 'Fira Sans', 'Open Sans', sans-serif;
  font-weight: ${(props) => props.fontWeight || 'bold'};
  color: ${(props) => props.color || 'inherit'};
  font-size: ${(props) => props.size || 12}px;
  margin-block-start: 1em;
  margin-block-end: ${(props) => (get(props, 'marginBottom', true) ? '1em' : '0')};
`;

export const HeaderContainer = Styled.div`
  padding-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
