import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Box,
  Flex,
  Button,
  Text,
  Heading,
  Divider,
  Link as SambaLink,
  InputWithLabel,
  Spinner,
  Logo,
  Message,
} from 'samba-ui';
import styled from 'styled-components';

import { APPLICATION_NAME } from '../../utils/constants';
import { validateEmail } from '../../utils/validators';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Signin = ({ onClick, onSambaLoginClick }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ email: null, password: null });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSambaEmail, setIsSambaEmail] = useState(false);

  const sambaEmailErrorMsg = 'Samba TV employees must sign in with Google';

  const validateEmailField = (value) => {
    const isEmailValid = validateEmail(value);

    const isSambaEmailDomain = value.indexOf('@samba.tv') > -1;
    setIsSambaEmail(isSambaEmailDomain);

    if (!value) {
      return 'Email is required';
    }

    if (isSambaEmailDomain) {
      return sambaEmailErrorMsg;
    }

    if (!isEmailValid) {
      return 'Invalid Email';
    }

    return null;
  };

  const validatePasswordField = (value) => {
    return value ? null : 'Password is required';
  };

  const onEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);

    const isSambaEmailDomain = value.indexOf('@samba.tv') > -1;
    setIsSambaEmail(isSambaEmailDomain);

    setErrors({
      ...errors,
      email: isSambaEmailDomain ? sambaEmailErrorMsg : null,
    });
  };

  // Clear errors on re-entry to input
  const onEmailFocus = () => {
    setErrors({ ...errors, email: null });
    setIsSambaEmail(false);
  };

  // Validation onBlur
  const onEmailBlur = (e) => {
    const { value } = e.target;

    setErrors({
      ...errors,
      email: validateEmailField(value),
    });
  };

  const onPasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);

    setErrors({
      ...errors,
      password: validatePasswordField(value),
    });
  };

  const onPasswordFocus = () => {
    setErrors({ ...errors, password: null });
  };

  const onPasswordBlur = (e) => {
    const { value } = e.target;

    setErrors({
      ...errors,
      password: validatePasswordField(value),
    });
  };

  const validateForm = () => {
    setErrors({
      email: validateEmailField(email),
      password: validatePasswordField(password),
    });
  };

  const onSigninClick = (e) => {
    e.preventDefault();
    if (isDisabled()) {
      validateForm();
      return;
    }

    setLoading(true);
    onClick({ email, password }, (err) => {
      setLoading(false);
      if (err) {
        setError(err.description);
      }
    });
  };

  const isDisabled = () => !email || !password || !validateEmail(email) || isSambaEmail;

  return (
    <Box color={'black'} width={376} bg='white' border={1} borderColor='#EBEAFB' borderRadius={8}>
      <Box px={5} pt={5}>
        <Text color='palette.ink.dark' fontSize={1} mb={2}>
          Sign into
        </Text>
        <Heading fontWeight={500} color='palette.ink.dark'>
          {APPLICATION_NAME}
        </Heading>
        {isSambaEmail && (
          <Box
            mb={3}
            bg='palette.blue.light'
            border={1}
            borderColor='palette.blue.lightHover'
            textAlign='center'
            py={3}
            borderRadius={4}
          >
            <Text fontSize={1} mb={2} color='primary'>
              Samba TV employee:
            </Text>
            <SambaLink
              style={{ display: 'block' }}
              fontWeight={500}
              fontSize={1}
              color='primary'
              onClick={onSambaLoginClick}
            >
              Click here to sign in with Google
            </SambaLink>
          </Box>
        )}
        {error && !loading && (
          <Message mb={3} variant='alert'>
            {error}
          </Message>
        )}
        <form onSubmit={onSigninClick} id='signin'>
          <Box mt={4} mb={3}>
            <InputWithLabel
              value={email}
              onChange={onEmailChange}
              error={errors.email}
              onBlur={onEmailBlur}
              onFocus={onEmailFocus}
              type='text'
              labelTitle='Email'
              placeholder='name@company.com'
              style={{ width: '100%' }}
            />
          </Box>

          <Box mb={2}>
            <InputWithLabel
              value={password}
              onChange={onPasswordChange}
              onFocus={onPasswordFocus}
              onBlur={onPasswordBlur}
              type='password'
              error={errors.password}
              labelTitle='Password'
              placeholder='Password'
              style={{ width: '100%' }}
            />
          </Box>
          <Box mt={2} mb={4}>
            <StyledLink to='forgotpassword'>
              <Text fontSize={0} color='primary' ml={1} fontWeight={1}>
                Forgot your password?
              </Text>
            </StyledLink>
          </Box>
          <Button width={'100%'} variant='primary' readonly={isDisabled()} onClick={onSigninClick}>
            {loading ? <Spinner variant='xsmall' color='white' /> : 'Sign in'}
          </Button>
        </form>
        <Flex mt={3} alignItems='flex-end' justifyContent='center' fontWeight={1}>
          <Text color='palette.ink.dark' fontSize={0}>
            No account?
          </Text>
          <Box>
            <StyledLink to='signup'>
              <Text fontSize={0} color='primary' ml={1}>
                Click here to sign up
              </Text>
            </StyledLink>
          </Box>
        </Flex>
      </Box>
      <Box mt={'26px'}>
        <Divider bg='#E9E9EE' mb={4} />
        <Box my={4} px={5}>
          <Button backgroundColor='#050511 !important' borderRadius='20px' width={'100%'} onClick={onSambaLoginClick}>
            <Flex alignItems='center' width={'100%'} height={25}>
              <Box mr={4}>
                <Logo color='white' size={24} />
              </Box>
              <Text mb={1} fontSize={0} fontWeight={1} color='white'>
                Sign in with your Samba TV email
              </Text>
            </Flex>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

Signin.propTypes = {
  onClick: PropTypes.func,
  onSambaLoginClick: PropTypes.func,
};

export default Signin;
