import size from 'lodash/size';
import React from 'react';
import Styled from 'styled-components';
import { Dropdown, InputWithLabel } from 'samba-ui';
import { expiresInOptions } from './constants';

import { StyledText } from '../../../../../components/Text';

const GuidContainer = Styled.div`
  align-self: center;
  text-align: center;
`;

const FormContainer = Styled.div`
  width: 324px;
`;

const LabelContainer = Styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
`;

const Link = Styled.span`
  font-weight: bold;
  cursor: pointer;
`;

const ShareForm = ({ externalAccess, errors, deviceId, isMaxShared, values, onPressDevices, setFormValue }) => {
  return (
    <>
      {isMaxShared ? (
        <>
          <StyledText size={14} color={'black'}>
            You have reached the capacity for shared devices. Please reach out to support if you need further
            assistance.
          </StyledText>
        </>
      ) : (
        <>
          <StyledText size={14} color={'black'}>
            Enter the external IP for the network you would like to grant temporary access to the device with the ID
            ending in...
          </StyledText>
          <GuidContainer>
            {deviceId ? (
              <StyledText color={'black'} size={22}>
                ...{deviceId.slice(-8)}
              </StyledText>
            ) : null}
          </GuidContainer>
        </>
      )}

      <FormContainer>
        <InputWithLabel
          disabled={isMaxShared}
          placeholder='127.0.0.1'
          size='medium'
          labelTitle='External IP'
          value={values.externalIp}
          error={errors.externalIp}
          onChange={(e) => {
            setFormValue('externalIp', e.target.value);
          }}
        />
        <div style={{ position: 'relative', paddingTop: '10px' }}>
          <LabelContainer>
            <label htmlFor='expiresIn'>Expires In</label>
          </LabelContainer>
          <Dropdown
            disabled={isMaxShared}
            options={expiresInOptions}
            selected={values.expiresIn}
            onSelect={(selected) => setFormValue('expiresIn', selected)}
          />
        </div>
        {externalAccess && size(externalAccess) ? (
          <div style={{ position: 'relative', paddingTop: '10px' }}>
            <StyledText fontWeight={'normal'}>
              View networks with existing access <Link onClick={onPressDevices}>here</Link>
            </StyledText>
          </div>
        ) : null}
      </FormContainer>
    </>
  );
};

export default ShareForm;
