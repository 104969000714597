import { ButtonContainer, ButtonText } from '../styles';
import React from 'react';

const ExternalSharedButton = () => {
  return (
    <ButtonContainer disabled>
      <ButtonText color={'white'} size={12}>
        Shared with you!
      </ButtonText>
    </ButtonContainer>
  );
};

export default ExternalSharedButton;
