import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Heading, Box, InputWithLabel, Button } from 'samba-ui';

import PasswordRules from './PasswordRules';
import { PASSWORD_MIN_LENGTH } from '../../utils/config';

const ResetPassword = ({ onClick }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [rules, setRules] = useState({
    password: {
      has8chars: false,
      hasNumber: false,
      hasUppercase: false,
      hasLowercase: false,
    },
    confirmPassword: null,
  });

  const onResetPasswordClick = (e) => {
    e.preventDefault();
    const body = {
      password,
      confirmPassword,
    };
    onClick(body);
  };

  const handlePasswordInput = (e) => {
    const { value } = e.target;
    const passwordError = {
      has8chars: value.length >= PASSWORD_MIN_LENGTH,
      hasNumber: /\d/.test(value),
      hasUppercase: value.toLowerCase() !== value,
      hasLowercase: value.toUpperCase() !== value,
    };
    setPassword(value);
    setRules({
      password: passwordError,
      confirmPassword: rules.confirmPassword,
    });
  };

  const setConfirmPasswordError = (value = null) => {
    setRules({
      password: rules.password,
      confirmPassword: (value || confirmPassword) !== password ? "password doesn't match" : null,
    });
  };

  const handleConfirmPasswordInput = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
    setConfirmPasswordError(value);
  };

  const handleConfirmPasswordFocus = () => {
    setConfirmPasswordError();
  };

  const isDisabled = () =>
    !confirmPassword || rules.confirmPassword || Object.values(rules.password).filter((p) => !p).length > 0;

  return (
    <form onSubmit={onResetPasswordClick} id='resetpassword'>
      <Box width={368} bg='white' p={5} border={1} borderColor='#EBEAFB' borderRadius={8}>
        <Heading fontWeight={500} color='palette.ink.dark'>
          Choose a new password
        </Heading>
        <Box mt={5} mb={4}>
          <InputWithLabel
            onChange={handlePasswordInput}
            value={password}
            type='password'
            labelTitle='Password'
            placeholder='Password'
            style={{ width: '100%' }}
          />
        </Box>
        <Box my={4}>
          <InputWithLabel
            onChange={handleConfirmPasswordInput}
            onFocus={handleConfirmPasswordFocus}
            type='password'
            value={confirmPassword}
            error={rules['confirmPassword']}
            labelTitle='Confirm password'
            placeholder='Confirm password'
            style={{ width: '100%' }}
          />
        </Box>

        <PasswordRules {...rules.password} />

        <Button width={'100%'} variant='primary' onClick={onResetPasswordClick} disabled={isDisabled()}>
          Update Password
        </Button>
      </Box>
    </form>
  );
};

ResetPassword.propTypes = {
  onClick: PropTypes.func,
};

export default ResetPassword;
