import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from 'samba-ui';

const PasswordRules = ({ has8chars, hasNumber, hasUppercase, hasLowercase }) => {
  return (
    <>
      <Flex my={3} alignItems='center' justifyContent='space-between'>
        <Flex alignItems='center' pl={3}>
          <SinglePasswordRule title='8 characters min' valid={has8chars} />
        </Flex>
        <Flex alignItems='center' pr={3}>
          <SinglePasswordRule title='1 uppercase' valid={hasUppercase} />
        </Flex>
      </Flex>
      <Flex my={3} alignItems='center' justifyContent='space-between'>
        <Flex alignItems='center' pl={3}>
          <SinglePasswordRule title='1 number' valid={hasNumber} />
        </Flex>
        <Flex alignItems='center' pr={3}>
          <SinglePasswordRule title='1 lowercase' valid={hasLowercase} />
        </Flex>
      </Flex>
    </>
  );
};

PasswordRules.propTypes = {
  has8chars: PropTypes.bool,
  hasNumber: PropTypes.bool,
  hasUppercase: PropTypes.bool,
  hasLowercase: PropTypes.bool,
};

export default PasswordRules;

const SinglePasswordRule = ({ valid, title }) => {
  return (
    <>
      <Text mr={2} color={valid ? 'primary' : 'alert'} fontWeight={500} fontSize={0}>
        {valid ? '✔' : '!'}
      </Text>
      <Text color='palette.ink.normal' fontSize={1}>
        {title}
      </Text>
    </>
  );
};

SinglePasswordRule.propTypes = {
  valid: PropTypes.bool,
  title: PropTypes.string,
};
