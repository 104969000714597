import axios from 'axios';

import { getAccessToken } from '../../utils/accessToken';

const { REACT_APP_FEATURE_FLAG_BASE_URL = ''} = process.env;

const buildRequestUrl = () => `${REACT_APP_FEATURE_FLAG_BASE_URL}/graphql`;
const buildRequestConfig = (params) => {
  const config = { params };
  config.headers = { Authorization: `Bearer ${getAccessToken()}` };
  return config;
};

export const graphqlFetch = async (query, variables, params) => {
  const url = buildRequestUrl();
  const config = buildRequestConfig(params);
  try {
    return await axios.post(url, { query, variables }, config);
  } catch (err) {
    return {
      data: false,
      error: true,
    };
  }
};

export const graphqlMutation = async (query, variables, params) => {
  const url = buildRequestUrl();
  const config = buildRequestConfig(params);
  try {
    return await axios.post(url, { query, variables }, config);
  } catch (err) {
    return {
      data: false,
      error: true,
    };
  }
};
