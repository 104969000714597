import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Flex, Text, Button, Heading, InputWithLabel, Link as SambaLink, Spinner, Message } from 'samba-ui';
import styled from 'styled-components';
import { validateEmail } from '../../utils/validators';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const SignUp = ({ onClick, onSambaLoginClick }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({
    firstName: null,
    lastName: null,
    companyName: null,
    email: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSambaEmail, setIsSambaEmail] = useState(false);

  const sambaEmailErrorMsg = 'Are you a Samba TV employee?';

  // Value must be passed.
  // Extracting it directly from the state leads to inconsistent behavior.
  const validateFirstNameField = (value) => {
    return value ? null : 'First name is required';
  };

  const validateLastNameField = (value) => {
    return value ? null : 'Last name is required';
  };

  const validateCompanyField = (value) => {
    return value ? null : 'Company name is required';
  };

  const validateEmailField = (value) => {
    const isEmailValid = validateEmail(value);

    const isSambaEmailDomain = value.indexOf('@samba.tv') > -1;
    setIsSambaEmail(isSambaEmailDomain);

    if (!value) {
      return 'Email is required';
    }

    if (isSambaEmailDomain) {
      return sambaEmailErrorMsg;
    }

    if (!isEmailValid) {
      return 'Invalid Email';
    }

    return null;
  };

  const onFirstNameChange = (e) => {
    const { value } = e.target;
    setFirstName(value);

    setErrors({ ...errors, firstName: validateFirstNameField(value) });
  };

  const onFirstNameFocus = () => {
    setErrors({ ...errors, firstName: null });
  };

  // Validation for UI purposes takes place onBlur
  const onFirstNameBlur = (e) => {
    const { value } = e.target;
    setErrors({
      ...errors,
      firstName: validateFirstNameField(value),
    });
  };

  const onLastNameChange = (e) => {
    const { value } = e.target;
    setLastName(value);

    setErrors({ ...errors, lastName: validateLastNameField(value) });
  };

  const onLastNameFocus = () => {
    setErrors({ ...errors, lastName: null });
  };

  // Validation for UI purposes takes place onBlur
  const onLastNameBlur = (e) => {
    const { value } = e.target;
    setErrors({
      ...errors,
      lastName: validateLastNameField(value),
    });
  };

  const onCompanyNameChange = (e) => {
    const { value } = e.target;
    setCompanyName(value);

    setErrors({
      ...errors,
      companyName: validateCompanyField(value),
    });
  };

  const onCompanyNameFocus = () => {
    setErrors({ ...errors, companyName: null });
  };

  // Validation for UI purposes takes place onBlur
  const onCompanyNameBlur = (e) => {
    const { value } = e.target;
    setErrors({
      ...errors,
      companyName: validateCompanyField(value),
    });
  };

  const onEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);

    const isSambaEmailDomain = value.indexOf('@samba.tv') > -1;
    setIsSambaEmail(isSambaEmailDomain);

    // Trigger the samba email error immediately on valid samba email.
    setErrors({
      ...errors,
      email: isSambaEmailDomain ? sambaEmailErrorMsg : null,
    });
  };

  // Clear errors on focus
  const onEmailFocus = () => {
    setErrors({ ...errors, email: null });
    setIsSambaEmail(false);
  };

  // Validation (only for UI purposes) onBlur
  const onEmailBlur = (e) => {
    const { value } = e.target;
    setErrors({
      ...errors,
      email: validateEmailField(value),
    });
  };

  const validateForm = () => {
    setErrors({
      ...errors,
      firstName: validateFirstNameField(firstName),
      lastName: validateLastNameField(lastName),
      companyName: validateCompanyField(companyName),
      email: validateEmailField(email),
    });
  };

  const onSignupClick = (e) => {
    e.preventDefault();
    if (isDisabled()) {
      validateForm();
      return;
    }

    setLoading(true);
    const body = { firstName, lastName, companyName, email };
    onClick(body, (err) => {
      setLoading(false);
      if (err) {
        setError(err);
      }
    });
  };

  const isDisabled = () => !firstName || !lastName || !companyName || !(email && validateEmail(email)) || isSambaEmail;

  return (
    <form onSubmit={onSignupClick} id='signup'>
      <Box color={'black'} width={388} bg='white' p={5} border={1} borderColor='#EBEAFB' borderRadius={8}>
        <Heading fontSize={4} fontWeight={500}>
          Create your account
        </Heading>
        <Flex mt={3} mb={5} alignItems='flex-end'>
          <Text color='palette.ink.dark' fontSize={0}>
            Have an account?
          </Text>
          <StyledLink to='/'>
            <Text fontSize={0} color='primary' ml={1}>
              Click here to sign in
            </Text>
          </StyledLink>
        </Flex>
        {error && !loading && (
          <Message mb={3} variant='alert'>
            {error}
          </Message>
        )}
        <Box mb={3}>
          <InputWithLabel
            placeholder='First name'
            labelTitle='First name'
            value={firstName}
            error={errors.firstName}
            onChange={onFirstNameChange}
            onFocus={onFirstNameFocus}
            onBlur={onFirstNameBlur}
            style={{ width: '100%' }}
          />
        </Box>
        <Box mb={3}>
          <InputWithLabel
            placeholder='Last name'
            labelTitle='Last name'
            value={lastName}
            error={errors.lastName}
            onChange={onLastNameChange}
            onFocus={onLastNameFocus}
            onBlur={onLastNameBlur}
            style={{ width: '100%' }}
          />
        </Box>
        <Box mb={3}>
          <InputWithLabel
            placeholder='Where you work'
            labelTitle='Company name'
            value={companyName}
            error={errors.companyName}
            onChange={onCompanyNameChange}
            onFocus={onCompanyNameFocus}
            onBlur={onCompanyNameBlur}
            style={{ width: '100%' }}
          />
        </Box>
        <Box mb={5}>
          <InputWithLabel
            placeholder='name@company.com'
            value={email}
            labelTitle='Company provided email'
            error={errors.email}
            onChange={onEmailChange}
            onBlur={onEmailBlur}
            onFocus={onEmailFocus}
            style={{ width: '100%' }}
          />
          {isSambaEmail && (
            <Box mt={1}>
              <SambaLink fontSize={0} color='primary' ml={1} mb={1} onClick={onSambaLoginClick}>
                Click here to access the Real Time TV Integrations
              </SambaLink>
            </Box>
          )}
        </Box>
        <Button width={'100%'} height={50} variant='primary' readonly={isDisabled()} onClick={onSignupClick}>
          {loading ? (
            <Flex alignItems='center' flexDirection='column'>
              <Spinner variant='xsmall' color='white' />
            </Flex>
          ) : (
            'Submit for approval'
          )}
        </Button>
      </Box>
    </form>
  );
};

SignUp.propTypes = {
  onClick: PropTypes.func,
  onSambaLoginClick: PropTypes.func,
};

export default SignUp;
