import React from 'react';
import { HeaderContainer, UppercaseText } from '../../../components/Text';

const IPAddress = ({ loading, error, ipAddress }) => {
  return (
    <HeaderContainer>
      <UppercaseText marginBottom={false} size={18}>
        IP Address: {loading || error ? '' : ipAddress}
      </UppercaseText>
    </HeaderContainer>
  );
};

export default IPAddress;
