import get from 'lodash/get';
import React, { useMemo } from 'react';
import Styled from 'styled-components';
import { Dropdown } from 'samba-ui';

import { StyledText } from '../../../../../components/Text';

const GuidContainer = Styled.div`
  align-self: center;
  text-align: center;
`;

const FormContainer = Styled.div`
  width: 324px;
`;

const LabelContainer = Styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
`;

const SettingsForm = ({ errors, device, values, setFormValue }) => {
  const deviceVariants = useMemo(() => {
    return get(device, 'variants', []).map(variant => ({ ...variant, label: variant.name, value: variant.name }));
  }, [device]);
  return (
    <>
      <StyledText size={14} color={'black'}>
        Select the SAMBA ACR virgin mode settings test version to be displayed. A power cycle is required for the change to take effect.
      </StyledText>
      <GuidContainer>
        {device ? (
          <StyledText color={'black'} size={22}>
            ...{get(device, 'guid').slice(-8)}
          </StyledText>
        ) : null}
      </GuidContainer>
      <FormContainer>
        <div style={{ position: 'relative', paddingTop: '10px' }}>
          {errors && errors.submit ? (
            <StyledText size={11} color={'alert'}>
              {errors.submit}
            </StyledText>
          ) : null}
          <LabelContainer>
            <label htmlFor='version'>Test Version</label>
          </LabelContainer>
          <Dropdown
            options={deviceVariants}
            selected={values.variant}
            error={errors.variant}
            onSelect={(selected) => setFormValue('variant', selected)}
          />
        </div>
      </FormContainer>
    </>
  );
};

export default SettingsForm;
