import Styled from 'styled-components';

export const CardContainer = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #B9B9B9;
  padding: 30px;
  margin: 20px 0;
`;

export const ImageContainer = Styled.div`
  padding: 10px;
`;

export const ContentContainer = Styled.div`
  flex: 1;
  padding: 10px;
  flex-direction: column;
`;

export const TagsContainer = Styled.div`
  display: flex;
  flex-direction: row;
`;
