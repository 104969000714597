import refresh from '../../../assets/refresh.svg';
import React from 'react';
import Styled, { keyframes } from 'styled-components';

const RefreshButton = Styled.li`
  padding: 0 10px;  
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const rotate360 = keyframes`
  100% { 
    transform:rotate(360deg); 
  }
`;

const LoadingButton = Styled.img`
  height: 20px;
  width: 20px;
  animation: ${rotate360} 1s linear infinite;
`;

const RefreshingIcon = ({ onClick }) => {
  return (
    <RefreshButton onClick={onClick}>
      <LoadingButton src={refresh} alt='Loading content state' />
    </RefreshButton>
  );
};

export default RefreshingIcon;
