import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Heading, Box, Text, InputWithLabel, Button, Flex } from 'samba-ui';
import styled from 'styled-components';
import { forgotPassword } from '../../services/authentication';
import { validateEmail } from '../../utils/validators';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const isDisabled = () => !validateEmail(email);

  const onResetPasswordClick = async (e) => {
    e.preventDefault();

    try {
      const res = await forgotPassword({ email });
      if (res.status === 200) {
        return window.location.replace('/forgotpasswordsuccess');
      }
      setError("Your email address doesn't exist. Please sign up");
    } catch (error) {
      setError("Your email address doesn't exist. Please sign up");
    }
  };

  return (
    <form onSubmit={onResetPasswordClick} id='forgotpassword'>
      <Box width={388} bg='white' p={5} border={1} borderColor='#EBEAFB' borderRadius={8}>
        <Heading fontWeight={500} color='palette.ink.dark'>
          Forgot your password?
        </Heading>
        <Text color='palette.ink.dark' opacity='0.5' fontSize={1} mt={2}>
          {"We'll email you instructions to reset your password."}
        </Text>

        <Flex my={3} alignItems='flex-end'>
          <Text color='palette.ink.dark' fontSize={0} mr={1}>
            Do you magically remember your password?
          </Text>
          <StyledLink to='/'>
            <Text fontSize={0} color='primary'>
              Sign in
            </Text>
          </StyledLink>
        </Flex>
        <Box my={5}>
          <InputWithLabel
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            error={error}
            type='text'
            labelTitle='Email'
            placeholder='Email'
            style={{ width: '100%' }}
          />
        </Box>

        <Button width={'100%'} variant='primary' onClick={onResetPasswordClick} disabled={isDisabled()}>
          Reset my password
        </Button>
      </Box>
    </form>
  );
};

export default ForgotPassword;
