import _ from 'lodash';
import React, { useMemo } from 'react';

import Image from './Image';
import Tag from './Tag';
import { CardContainer, ImageContainer, ContentContainer, TagsContainer } from './styles';
import { StyledText } from '../../../../components/Text';
import { getContentImage } from '../../../../utils/images';

const AdCard = ({ content }) => {
  const adDetails = useMemo(() => {
    if (!content) {
      return {
        productName: '',
        advertiserName: '',
        parentName: '',
        imageUrl: '',
      };
    }

    const products = _.get(content, 'products', []);
    const product = _.first(products);
    const productName = _.get(product, 'product.name', '');
    const advertiserName = _.get(product, 'advertiser.name', '');
    const parentName = _.get(product, 'parent.name', '');

    const imageUrl = getContentImage(content);

    return {
      productName,
      advertiserName,
      parentName,
      imageUrl,
      hasContent: !!(productName || advertiserName || parentName),
    };
  }, [content]);

  if (!_.get(adDetails, 'hasContent', false)) {
    return null;
  }

  return (
    <CardContainer>
      <ImageContainer>
        <Image source={adDetails.imageUrl} />
      </ImageContainer>
      <ContentContainer>
        {adDetails.hasContent ? (
          <>
            <StyledText size={18}>{adDetails.productName}</StyledText>
            <TagsContainer>
              <Tag label='Commercial' />
            </TagsContainer>
            <p>
              {_.get(adDetails, 'advertiserName', '')}, {_.get(adDetails, 'parentName', '')}
            </p>
          </>
        ) : null}
      </ContentContainer>
    </CardContainer>
  );
};

export default AdCard;
