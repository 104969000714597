import get from 'lodash/get';

const EMPTY_CONTENT = {
  content_id: '',
  search_id: '',
  total_num_search_calls: '',
  fingerprints_in_last_batch: '',
};
export const getDevicesFromResponse = (response) => get(response, 'data', []);
export const getDeviceInfoFromResponse = (response, deviceId) =>
  get(response, `data.results[${deviceId}].device_info`, {});
export const getDeviceContentStateFromResponse = (response, deviceId) =>
  get(response, `data.results[${deviceId}].cid_state`, EMPTY_CONTENT);
