import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading } from 'samba-ui';
import { getURIParam } from '../../utils/parsers';

const Thanks = () => {
  return (
    <Box mt='72px' textAlign='center'>
      <Heading fontSize={5} color='white' fontWeight={700}>
        Thank you for signing up!
      </Heading>
      <Box mt={3}>
        <Heading fontSize={2} color='white' fontWeight={400}>
          Further instructions have been sent to the provided email address:
          {` ${getURIParam('email')}`}
        </Heading>
      </Box>
    </Box>
  );
};

Thanks.propTypes = {
  email: PropTypes.string,
};

export default Thanks;
