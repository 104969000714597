import React from 'react';
import Styled from 'styled-components';

import DEFAULT_IMAGE from '../../../../assets/default-screenshot.png';
import ImageWithPlaceholder from '../../../../components/Image';

const ImageMaxHeight = Styled(ImageWithPlaceholder)`
  display: block;
  max-width:273px;
  max-height:145px;
  width: auto;
  height: auto;
`;

const Image = ({ source }) => {
  return <ImageMaxHeight src={source} placeHolder={DEFAULT_IMAGE} />;
};

export default Image;
