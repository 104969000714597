import React, { useContext } from 'react';
import Styled from 'styled-components';
import { useAsync } from 'react-async-hook';

import { getDeviceInfo } from './actions';
import { HeaderContainer, UppercaseText } from '../../../components/Text';
import PrettyJson from '../../../components/prettyJson/PrettyJson';
import { containerLightBackground, textDark } from '../../../utils/cssConstants';
import { DEFAULT_ERROR_MESSAGE } from '../../../utils/constants';
import { UserContext } from '../../../utils/contexts/UserContext';
import { DevicesContext } from '../../../utils/contexts/devices/DevicesContext';

const DeviceInfoContainer = Styled.div`
  border-radius: 5px;
  padding: 10px 10px 10px 30px;
  color: ${textDark};
  background-color: ${containerLightBackground};
  margin: 0;
  min-height: 450px;
`;

const LabelContainer = Styled(HeaderContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const DeviceInfo = ({ error, loading }) => {
  const { isSambaEmployee } = useContext(UserContext);
  const { selectedDevice } = useContext(DevicesContext);
  const {
    loading: deviceLoading,
    error: deviceError,
    result: device,
  } = useAsync(() => {
    if (!selectedDevice) {
      return;
    }

    return getDeviceInfo(selectedDevice);
  }, [selectedDevice]);

  if (!selectedDevice || !isSambaEmployee) {
    return null;
  }

  return (
    <div>
      <LabelContainer>
        <UppercaseText size={18}>Device Info</UppercaseText>
        <UppercaseText size={18}>{loading || deviceLoading ? 'Loading...' : ''}</UppercaseText>
      </LabelContainer>
      <DeviceInfoContainer>
        {!loading && !deviceLoading && selectedDevice ? (
          <PrettyJson useLightTheme id='device-info' data={device} textTransform='none' />
        ) : null}
        {error || deviceError ? <UppercaseText size={14}>{DEFAULT_ERROR_MESSAGE}</UppercaseText> : null}
      </DeviceInfoContainer>
    </div>
  );
};

export default DeviceInfo;
