import React from 'react';
import ReactJson from 'react-json-view/dist/main';

import { darkTheme, lightTheme } from './themes';

export const PrettyJson = ({ data, id, useLightTheme, textTransform = 'none' }) => {
  return (
    <ReactJson
      id={id}
      src={data}
      theme={useLightTheme ? lightTheme : darkTheme}
      indentWidth={2}
      quotesOnKeys={false}
      quotesOnValues={false}
      displayObjectSize={false}
      displayDataTypes={false}
      style={{
        fontFamily: 'Fira Sans, Open Sans, sans-serif',
        textTransform,
        fontSize: '14px',
      }}
    />
  );
};

export default PrettyJson;
