import React, { useCallback, useState } from 'react';
import Styled from 'styled-components';

// TODO: better location
const ImageMaxHeight = Styled.img`
  display: block;
  max-width:273px;
  max-height:145px;
  width: auto;
  height: auto;
`;

const Image = ({ placeHolder, src, alt }) => {
  const [imageSource, setImageSource] = useState();
  const [imageError, setImageError] = useState(false);
  const onErrorImage = useCallback(() => {
    if (imageError) {
      return;
    }
    setImageError(true);
    setImageSource(placeHolder);
  }, [placeHolder, imageError]);
  return <ImageMaxHeight alt={alt} src={imageSource || src} onError={onErrorImage} />;
};

export default Image;
