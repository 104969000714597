import React, { useContext } from 'react';
import Styled from 'styled-components';
import { Checkbox } from 'samba-ui';

import DeviceList from './DeviceList';
import ContentStateContainer from '../content/ContentStateContainer';
import DeviceInfo from '../deviceInfo/DeviceInfo';
import { HeaderContainer, UppercaseText } from '../../../components/Text';
import { DevicesContext } from '../../../utils/contexts/devices/DevicesContext';

const ExternalFilterContainer = Styled.div`
  flex-direction: row;
  min-width: 100px;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

const Devices = ({ error, loading, onSelectDevice, selectedDevice }) => {
  const { availableDevices, filterExternal, onToggleFilterExternal } = useContext(DevicesContext);
  return (
    <>
      <HeaderContainer>
        <UppercaseText size={18}>Devices: {loading ? 'Loading...' : ''}</UppercaseText>
        <ExternalFilterContainer>
          <UppercaseText>Shared</UppercaseText>
          <Checkbox id={'filter-shared'} color={'#000000'} checked={filterExternal} onChange={onToggleFilterExternal} />
        </ExternalFilterContainer>
      </HeaderContainer>
      <DeviceList
        error={error}
        devices={availableDevices}
        loading={loading}
        selectedDevice={selectedDevice}
        onSelectDevice={onSelectDevice}
      />
      <ContentStateContainer error={error} selectedDevice={selectedDevice} />
      <DeviceInfo error={error} loading={loading} />
    </>
  );
};

export default Devices;
