import { getAccessToken } from '../utils/accessToken';
import { AUTH_APPLICATION_NAME } from '../utils/constants';
import { getAuthenticationServerUrl } from './config';

const appPlatformServerUrl = getAuthenticationServerUrl();

export const resetPassword = (params, code) => {
  const body = { ...params, application: AUTH_APPLICATION_NAME };
  return fetch(`${appPlatformServerUrl}/forgotPassword/set?code=${code}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};

export const forgotPassword = (params) => {
  const body = { ...params, application: AUTH_APPLICATION_NAME };
  return fetch(`${appPlatformServerUrl}/forgotpassword/request`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};

export const register = (params) => {
  const body = { ...params, application: AUTH_APPLICATION_NAME };
  return fetch(`${appPlatformServerUrl}/register`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};

export const loadUserProfile = () => {
  return fetch(`${appPlatformServerUrl}/me/profile?exclude_dashboards=true`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};
