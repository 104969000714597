import get from 'lodash/get';
import React, { useState, createContext, useMemo } from 'react';
import { useAsync } from 'react-async-hook';

import logoutUtil from '../logout';
import { loadUserProfile } from '../../services/authentication';
import { AUTH_APPLICATION_NAME } from '../constants';

export const UserContext = createContext();
const SAMBA_ROLES = ['superadmin', 'superoperator'];

export const UserContextProvider = (props) => {
  const [user, setUser] = useState();
  const { loading, error } = useAsync(async () => {
    const resp = await loadUserProfile();
    if (resp.status === 401) {
      return setTimeout(logoutUtil, 100);
    }
    const { data } = await resp.json();
    setUser(data);
  }, []);

  const isSambaEmployee = useMemo(() => {
    return !user ? false : user.email.endsWith('samba.tv') && SAMBA_ROLES.includes(user.role);
  }, [user]);

  const hasPermission = useMemo(() => {
    if (loading || !user) {
      return true;
    }

    return get(user, 'applications', []).includes(AUTH_APPLICATION_NAME);
  }, [user, loading]);

  return (
    <UserContext.Provider value={{ user, loading, error, isSambaEmployee, hasPermission }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
