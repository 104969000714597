import isBoolean from 'lodash/isBoolean';
import { getDeviceVariants, setDeviceVariant } from './api';
import {
  getDeviceVariants as getDeviceVariantsStub,
} from './stub';
import { getUseStubData } from '../config';

export default function getApiServices(forcedStub) {
  const useStubData = isBoolean(forcedStub) ? forcedStub : getUseStubData();
  if (useStubData) {
    return {
      getDeviceVariants: getDeviceVariantsStub,
      setDeviceVariant: () => {},
    };
  }

  return {
    getDeviceVariants,
    setDeviceVariant,
  };
}
