import get from 'lodash/get';
import React, { useMemo } from 'react';
import Styled from 'styled-components';

import Device from './Device';
import NoResults from './EmptyDevice';
import LoadingDevices from './LoadingDevices';
import ErrorDevice from './ErrorDevice';

const DevicesContainer = Styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding: 15px 0;
`;

const DeviceList = ({ devices, error, loading, onSelectDevice, selectedDevice }) => {
  const { isLoading, isEmpty } = useMemo(() => {
    return {
      isLoading: loading,
      isEmpty: devices && devices.length === 0,
    };
  }, [devices, loading]);

  return (
    <DevicesContainer>
      {isLoading ? <LoadingDevices /> : null}
      {!isLoading && isEmpty ? <NoResults /> : null}
      {!isLoading && error ? <ErrorDevice /> : null}
      {devices && !isLoading && !isEmpty && !error
        ? devices.map((device) => {
            const deviceId = get(device, 'guid');
            return <Device device={device} key={deviceId} onSelectDevice={onSelectDevice} />;
          })
        : null}
    </DevicesContainer>
  );
};

export default DeviceList;
