import React from 'react';
import styled from 'styled-components';
import { Loader } from 'samba-ui';

const LoaderWrapper = styled.div`
  display: flex;
  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-color: white;
  justify-content: center;
  align-items: center;
`;

const LoadingBox = () => {
  return (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  );
};

export default LoadingBox;
