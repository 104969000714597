import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import Styled from 'styled-components';
import { useAsyncCallback } from 'react-async-hook';
import { Modal as SambaModal, Box, Button, IconSvg } from 'samba-ui';

import { addExternalAccess } from './actions';
import { expiresInValues } from './constants';
import ShareForm from './ShareForm';
import SharedDevicesList from './SharedDevicesList';
import { MAX_SHARED_DEVICES } from '../../../../../utils/constants';

const ModalContainer = Styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  color: black;
  min-height: 350px;
`;

const CloseButtonContainer = Styled.button`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const HeaderContainer = Styled.div`
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;
`;

const ShareModal = ({ externalAccess, deviceId, isOpen, closeModal, onSuccess }) => {
  const [page, setPage] = useState(() => {
    return externalAccess && _.size(externalAccess) >= MAX_SHARED_DEVICES ? 2 : 1;
  });
  const [values, setFormValues] = useState({ externalIp: '', expiresIn: expiresInValues.FOUR_HOURS });
  const [errors, setFormErrors] = useState({ externalIp: null, expiresIn: null });

  const setFormValue = useCallback(
    (field, value) => {
      setFormValues({ ...values, [field]: value });
    },
    [values],
  );

  const isMaxShared = useMemo(() => {
    return externalAccess && _.isArray(externalAccess) && _.size(externalAccess) >= MAX_SHARED_DEVICES;
  }, [externalAccess]);

  const { loading, execute: onSubmit } = useAsyncCallback(async () => {
    // TODO: Preferred form library?
    // Check values, set errors if necessary
    const errors = { externalIp: null, expiresIn: null };
    let hasErrors = false;
    if (!values.externalIp) {
      hasErrors = true;
      errors.externalIp = 'Required';
    } else if (
      !/^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(values.externalIp)
    ) {
      hasErrors = true;
      errors.externalIp = 'Invalid IP address';
    }

    if (!values.expiresIn) {
      hasErrors = true;
      errors.expiresIn = 'Required';
    }

    setFormErrors(errors);
    if (hasErrors) {
      return;
    }

    // Send to rtcid-api
    const expiration = expiresInValues[values.expiresIn.value];
    const results = await addExternalAccess(values.externalIp, deviceId, expiration.duration);
    const error = _.get(results, 'error', false);
    const data = _.get(results, 'data', false);

    if (error) {
      // TODO: Show error message
    } else {
      onSuccess(data);
    }
  });

  return (
    <SambaModal width={500} isOpen={isOpen} onRequestClose={closeModal}>
      <SambaModal.Header>
        <HeaderContainer>
          <CloseButtonContainer onClick={closeModal}>
            <IconSvg color={'blue'} name='close' />
          </CloseButtonContainer>
        </HeaderContainer>
      </SambaModal.Header>
      <SambaModal.Content>
        <ModalContainer>
          {page === 1 ? (
            <ShareForm
              errors={errors}
              externalAccess={externalAccess}
              deviceId={deviceId}
              isMaxShared={isMaxShared}
              onSuccess={onSuccess}
              onPressDevices={() => {
                setPage(2);
              }}
              setFormValue={setFormValue}
              values={values}
            />
          ) : (
            <SharedDevicesList deviceId={deviceId} externalAccess={externalAccess} />
          )}
        </ModalContainer>
      </SambaModal.Content>
      <SambaModal.Actions justifyContent='flex-end'>
        <Box mr={3}>
          <Button variant='secondary' onClick={closeModal}>
            Cancel
          </Button>
          {page === 1 ? (
            <Button disabled={loading || isMaxShared} onClick={onSubmit}>
              Share
            </Button>
          ) : null}
          {page === 2 ? (
            <Button
              disabled={loading}
              onClick={() => {
                // If we already have the max shared, we can't go to page to add more
                if (isMaxShared) {
                  closeModal();
                } else {
                  setPage(1);
                }
              }}
            >
              Back
            </Button>
          ) : null}
        </Box>
      </SambaModal.Actions>
    </SambaModal>
  );
};

export default ShareModal;
