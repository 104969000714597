import get from "lodash/get";
import React, {useCallback, useState} from 'react';
import Styled from 'styled-components';
import { useAsyncCallback } from 'react-async-hook';
import { Modal as SambaModal, Box, Button, IconSvg } from 'samba-ui';

import SettingsForm from './SettingsForm';
import {setDeviceVariant} from "../../../../../services/feature-flags/api";


const ModalContainer = Styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  color: black;
  min-height: 350px;
`;

const CloseButtonContainer = Styled.button`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const HeaderContainer = Styled.div`
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;
`;

const SettingsModal = ({ externalAccess, device, isOpen, closeModal, onSuccess }) => {
  const [values, setFormValues] = useState({ variant: get(device, 'selectedVariant') });
  const [errors, setFormErrors] = useState({ variant: null });

  const setFormValue = useCallback(
    (field, value) => {
      setFormValues({ ...values, [field]: value });
    },
    [values],
  );

  const { loading, execute: onSubmit } = useAsyncCallback(async () => {
    const errors = { variant: null };
    let hasErrors = false;
    if (!values.variant) {
      hasErrors = true;
      errors.variant = 'Required';
    }

    setFormErrors(errors);
    if (hasErrors) {
      return;
    }

    // Send to feature flags service
    try {
      const results = await setDeviceVariant(device.guid, get(values, 'variant.name'), device.variantName);
      const errors = get(results, 'errors', [])
      if (errors && errors.length) {
        setFormErrors({ submit: 'Unable to update device variant. Please try again, or contact support for assistance'})
      } else {
        onSuccess(values.variant);
      }
    } catch (err) {
      setFormErrors({ submit: 'Unable to update device variant. Please try again, or contact support for assistance'})
    }
  });

  return (
    <SambaModal width={500} isOpen={isOpen} onRequestClose={closeModal}>
      <SambaModal.Header>
        <HeaderContainer>
          <CloseButtonContainer onClick={closeModal}>
            <IconSvg color={'blue'} name='close' />
          </CloseButtonContainer>
        </HeaderContainer>
      </SambaModal.Header>
      <SambaModal.Content>
        <ModalContainer>
          <SettingsForm
            errors={errors}
            externalAccess={externalAccess}
            device={device}
            onSuccess={onSuccess}
            setFormValue={setFormValue}
            values={values}
          />
        </ModalContainer>
      </SambaModal.Content>
      <SambaModal.Actions justifyContent='flex-end'>
        <Box mr={3}>
          <Button variant='secondary' onClick={closeModal}>
            Cancel
          </Button>
          <Button disabled={loading} onClick={onSubmit}>
            Save
          </Button>
        </Box>
      </SambaModal.Actions>
    </SambaModal>
  );
};

export default SettingsModal;
