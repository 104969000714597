import castArray from 'lodash/castArray';
import get from 'lodash/get';
import maxBy from 'lodash/maxBy';
import omit from 'lodash/omit';
import trim from 'lodash/trim';
import size from 'lodash/size';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import Styled from 'styled-components';

import DeviceItem from './DeviceItem';
import Settings from "./buttons/settings/Settings";
import SettingsModal from "./buttons/settings/SettingsModal";
import ShareModal from './buttons/share/ShareModal';
import Share from './buttons/share/Share';
import SuccessBanner from '../../../components/SuccessBanner';
import PrettyJson from '../../../components/prettyJson/PrettyJson';
import { StyledText } from '../../../components/Text';
import useTimer from '../../../hooks/useTimer';
import { DevicesContext } from '../../../utils/contexts/devices/DevicesContext';
import {isFeatureFlagsEnabled} from "../../../services/config";

const DeviceInfoList = Styled.div`
  list-style: none;
  padding: 0;
`;

const DeviceInfoContainer = Styled.div`
  min-height: 90px;
  padding: 0 0;
`;

const DeviceText = Styled(StyledText)`
  margin: 5px 0;
`;

const DeviceButtonsContainer = Styled.div`
  display: flex;
  flex-direction: row;
`;


const Device = ({ device, onSelectDevice }) => {
  const [showModal, setShowModal] = useState();
  const { addExternalAccess, onDeviceShareExpires, selectedDevice, ipAddress, onSelectDeviceVariant } = useContext(DevicesContext);
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const onClick = useCallback(() => {
    return onSelectDevice(device);
  }, [onSelectDevice, device]);

  const onCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const onHideSuccessMessage = useCallback(() => {
    setShowSuccessBanner(false);
  }, []);

  const deviceDetails = useMemo(() => {
    if (!device) {
      return {
        isSelected: false,
      };
    }
    const deviceId = get(device, 'guid');
    const isSelected = deviceId && deviceId === selectedDevice;
    const externalAccess = get(device, 'external_access') ? get(device, 'external_access') : null;

    const isExternalDevice = castArray(externalAccess).some((external) => {
      const externalIp = get(external, 'external_ip', null);
      return externalIp === trim(ipAddress);
    });
    const ttlSeconds = get(maxBy(castArray(externalAccess), 'ttl_seconds'), 'ttl_seconds', 0) || 0;
    const variants = get(device, 'variants', []);
    const featureFlagsEnabled = isFeatureFlagsEnabled() && variants && size(variants) > 0;
    return {
      isSelected,
      ttlSeconds: isExternalDevice ? ttlSeconds : 0,
      isExternalDevice,
      externalAccess: externalAccess,
      make: get(device, 'make'),
      name: get(device, 'name') || get(device, 'model_id'),
      guid: get(device, 'guid'),
      highlightColor: isSelected ? 'black' : 'white',
      selectedVariant: get(device, 'selectedVariant'),
      featureFlagsEnabled,
      variants,
    };
  }, [selectedDevice, device, ipAddress]);

  useTimer(() => {
    onDeviceShareExpires(device);
  }, get(deviceDetails, 'ttlSeconds', 0) * 1000);

  const deviceExternalRemoved = useMemo(() => {
    return omit(device, ['external_access', 'variants', 'variantName', 'selectedVariant']);
  }, [device]);

  const onShareSuccess = (externalAccess) => {
    // Mark device as shared, close the modal, and show success banner
    setShowModal(false);
    addExternalAccess(device, externalAccess);
    const message = `Access to device ID ${device.guid} was granted to ${externalAccess.external_ip} and expires at ${externalAccess.expirationText}`
    setShowSuccessBanner(message);
  };

  const onVariantSelectedSuccess = useCallback((variant) => {
    onSelectDeviceVariant(device, variant);
    setShowModal(false);
    const message = `Device ID ${device.guid} was updated to use test version ${variant.name}. A power cycle is required for the change to take effect.`
    setShowSuccessBanner(message);
  }, [device, onSelectDeviceVariant]);

  return (
    <>
      <DeviceItem isSelected={deviceDetails.isSelected} onClick={onClick}>
        <DeviceInfoContainer>
          <>
            <DeviceText size={18} color={get(deviceDetails, 'highlightColor', 'black')}>
              {get(deviceDetails, 'make')} - {get(deviceDetails, 'name')}
            </DeviceText>
            <DeviceText size={18} color={get(deviceDetails, 'highlightColor', 'black')}>
              {get(deviceDetails, 'guid')}
            </DeviceText>
            <DeviceButtonsContainer>
              <Share
                isSelected={deviceDetails.isSelected}
                externalAccess={deviceDetails.externalAccess}
                isExternalDevice={deviceDetails.isExternalDevice}
                onClickShare={() => {
                  setShowModal('share');
                }}
                onClickShared={() => {
                  setShowModal('share');
                }}
              />
              <Settings
                featureFlagsEnabled={deviceDetails.featureFlagsEnabled}
                isSelected={deviceDetails.isSelected}
                onClick={() => {
                  setShowModal('settings');
                }}
              />
            </DeviceButtonsContainer>
          </>
        </DeviceInfoContainer>
        <DeviceInfoList>
          <PrettyJson useLightTheme={deviceDetails.isSelected} data={deviceExternalRemoved} id={'device'} />
        </DeviceInfoList>
      </DeviceItem>
      {showModal === 'share' ? (
        <ShareModal
          deviceId={get(deviceDetails, 'guid')}
          externalAccess={get(deviceDetails, 'externalAccess')}
          onSuccess={onShareSuccess}
          isOpen={showModal === 'share'}
          closeModal={onCloseModal}
        />
      ) : null}
      {showModal === 'settings' ? (
        <SettingsModal
          device={device}
          externalAccess={get(deviceDetails, 'externalAccess')}
          variants={get(deviceDetails, 'variants', [])}
          onSuccess={onVariantSelectedSuccess}
          isOpen={showModal === 'settings'}
          closeModal={onCloseModal}
        />
      ) : null}
      {showSuccessBanner ? <SuccessBanner message={showSuccessBanner} onClose={onHideSuccessMessage} /> : null}
    </>
  );
};

export default Device;
