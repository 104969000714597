import React from 'react';
import {ButtonContainer, ButtonText, ButtonTextContainer} from "../styles";

const Settings = ({ isSelected, featureFlagsEnabled, onClick }) => {
  if (!isSelected || !featureFlagsEnabled) {
    return null;
  }
  return (
    <ButtonContainer onClick={onClick}>
      <ButtonTextContainer>
        <ButtonText color={'white'} size={12}>
          Manage Settings Version
        </ButtonText>
      </ButtonTextContainer>
    </ButtonContainer>
  );
};

export default Settings;
