import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Heading, Spinner, Box, Flex, Text, InputWithLabel, Button, Divider, Checkbox, Link } from 'samba-ui';
import styled from 'styled-components';

import PasswordRules from './PasswordRules';
import { PASSWORD_MIN_LENGTH } from '../../utils/config';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const SetPassword = ({ onClick }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [rules, setRules] = useState({
    password: {
      has8chars: false,
      hasNumber: false,
      hasUppercase: false,
      hasLowercase: false,
    },
    confirmPassword: null,
  });

  const [tosAccepted, setTOS] = useState(false);
  const [ppAccepted, setPP] = useState(false);

  const onCreatePasswordClick = () => {
    setLoading(true);
    const body = {
      password,
      confirmPassword,
      tosAccepted,
      ppAccepted,
    };
    onClick(body);
  };

  const handlePasswordInput = (e) => {
    const { value } = e.target;
    const passwordError = {
      has8chars: value.length >= PASSWORD_MIN_LENGTH,
      hasNumber: /\d/.test(value),
      hasUppercase: value.toLowerCase() !== value,
      hasLowercase: value.toUpperCase() !== value,
    };
    setPassword(value);
    setRules({
      password: passwordError,
      confirmPassword: rules.confirmPassword,
    });
  };

  const setConfirmPasswordError = (value = null) => {
    setRules({
      password: rules.password,
      confirmPassword: (value || confirmPassword) !== password ? "password doesn't match" : null,
    });
  };

  const handleConfirmPasswordInput = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
    setConfirmPasswordError(value);
  };

  const handleConfirmPasswordFocus = () => {
    setConfirmPasswordError();
  };

  const isDisabled = () =>
    !ppAccepted ||
    !tosAccepted ||
    !confirmPassword ||
    rules.confirmPassword ||
    Object.values(rules.password).filter((p) => !p).length > 0;

  return (
    <Box id='setpassword' width={368} bg='white' p={5} border={1} borderColor='#EBEAFB' borderRadius={8}>
      <Text color='palette.ink.dark' opacity='0.5' fontSize={1} mb={1}>
        Complete your Samba TV registration
      </Text>
      <Heading fontWeight={500} color='palette.ink.dark'>
        Create a password
      </Heading>
      <Box mt={5} mb={4}>
        <InputWithLabel
          onChange={handlePasswordInput}
          value={password}
          type='password'
          labelTitle='Password'
          placeholder='Password'
          style={{ width: '100%' }}
        />
      </Box>
      <Box my={4}>
        <InputWithLabel
          onChange={handleConfirmPasswordInput}
          onFocus={handleConfirmPasswordFocus}
          type='password'
          value={confirmPassword}
          error={rules['confirmPassword']}
          labelTitle='Confirm password'
          placeholder='Confirm password'
          style={{ width: '100%' }}
        />
      </Box>

      <PasswordRules {...rules.password} />
      <Box mt={5} mb={4}>
        <Divider bg='palette.cloud.normal' />
      </Box>

      <Flex alignItems='center' mb={3}>
        <Box mr={2}>
          <Checkbox id={0} checked={tosAccepted} onChange={() => setTOS(!tosAccepted)} />
        </Box>
        <Flex pb={1} fontWeight={500}>
          <Text fontSize={0} color='secondary'>
            I agree to the
          </Text>
          <StyledLink fontSize={0} color='primary' ml={1} href='https://samba.tv/legal/terms/' target='_blank'>
            Terms of Service
          </StyledLink>
        </Flex>
      </Flex>

      <Flex alignItems='center' mb={3}>
        <Box mr={2}>
          <Checkbox id={1} checked={ppAccepted} onChange={() => setPP(!ppAccepted)} />
        </Box>
        <Flex pb={1} fontWeight={500}>
          <Text fontSize={0} color='secondary'>
            I have received notice of the
          </Text>
          <StyledLink fontSize={0} color='primary' ml={1} href='https://samba.tv/legal/privacy-policy/' target='_blank'>
            Privacy Policy
          </StyledLink>
        </Flex>
      </Flex>

      <Button width={'100%'} variant='primary' onClick={onCreatePasswordClick} disabled={isDisabled()}>
        {loading ? <Spinner variant='xsmall' color='white' /> : 'Create my Samba account'}
      </Button>

      <Box mt={4}>
        <Text color='palette.ink.light' fontSize={0} textAlign='left' lineHeight='16px'>
          For the Real Time TV Integrations Page, we retain the information you shared in your account application and
          keep record of your activity on the platform itself, including online identifiers, reports generated, and any
          account personalization, in order to provide our services and continuously improve them.
        </Text>
      </Box>
    </Box>
  );
};

SetPassword.propTypes = {
  onClick: PropTypes.func,
};

export default SetPassword;
