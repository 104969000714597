import axios from 'axios';

const { REACT_APP_API_BASE_URL, REACT_APP_API_AUTH_TOKEN } = process.env;

const buildRequestUrl = (endpoint) => `${REACT_APP_API_BASE_URL}/api/v1/${endpoint}`;
const buildRequestConfig = (params) => {
  const config = { params };
  config.headers = { Authorization: `Bearer ${REACT_APP_API_AUTH_TOKEN}` };
  return config;
};

export const apiGet = async (endpoint, params) => {
  const url = buildRequestUrl(endpoint);
  const config = buildRequestConfig(params);

  try {
    return await axios.get(url, config);
  } catch (err) {
    return {
      data: false,
      error: true,
    };
  }
};

export const apiPost = async (endpoint, body, params) => {
  const url = buildRequestUrl(endpoint);
  const config = buildRequestConfig(params);

  try {
    return await axios.post(url, body, config);
  } catch (err) {
    return {
      data: false,
      error: true,
    };
  }
};

export const apiDelete = async (endpoint, params) => {
  const url = buildRequestUrl(endpoint);
  const config = buildRequestConfig(params);

  try {
    return await axios.delete(url, config);
  } catch (err) {
    return {
      data: false,
      error: true,
    };
  }
};
