import React from 'react';
import Styled from 'styled-components';
import { IconSvg, Message } from 'samba-ui';

import { StyledText } from './Text';
import useInterval from '../hooks/useInterval';

const BannerContainer = Styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 44px;
`;

const FlexContainer = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CloseButtonContainer = Styled.button`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TEN_SECONDS = 10 * 1000;
const SuccessBanner = ({ message, onClose }) => {
  useInterval(onClose, TEN_SECONDS);
  return (
    <BannerContainer>
      <Message>
        <FlexContainer>
          <StyledText size={14} color={'black'}>
            {message}
          </StyledText>
          <CloseButtonContainer onClick={onClose}>
            <IconSvg color={'black'} name='close' />
          </CloseButtonContainer>
        </FlexContainer>
      </Message>
    </BannerContainer>
  );
};

export default SuccessBanner;
