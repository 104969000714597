import { useQueryParams, StringParam } from 'use-query-params';

const useRouterState = () => {
  // something like: ?x=123&q=foo&filters=a&filters=b&filters=c in the URL
  const [query, setQuery] = useQueryParams({ deviceId: StringParam });

  return {
    setQuery,
    setDeviceId: (deviceId) => setQuery({ deviceId: deviceId }),
    ...query,
  };
};

export default useRouterState;
