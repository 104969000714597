import get from 'lodash/get';
import React, { useMemo, useRef } from 'react';
import * as PropTypes from 'prop-types';
import Styled from 'styled-components';

import PrettyJson from '../../../components/prettyJson/PrettyJson';
import { StyledText, UppercaseText } from '../../../components/Text';
import { containerLightBackground, textDark } from '../../../utils/cssConstants';
import { CONTENT_TYPES, DEFAULT_ERROR_MESSAGE } from '../../../utils/constants';
import AdCard from './cards/AdCard';
import GeneralCard from './cards/GeneralCard';
import ProgramCard from './cards/ProgramCard';
import MovieCard from './cards/MovieCard';
import { CardContainer } from './cards/styles';

const ContentStateContainer = Styled.div`
  border-radius: 5px;
  background-color: ${containerLightBackground};
  padding: 10px 10px 10px 30px;
  color: ${textDark};
  min-height: 350px;
`;

const CONTENT_TYPE_MAP = {
  [CONTENT_TYPES.ADVERTISEMENT]: AdCard,
  1: MovieCard,
  2: ProgramCard,
  8: ProgramCard, // Show
  9: ProgramCard, // Miniseries
  7: GeneralCard, // Sporting event
  4: GeneralCard, // Commercial
  12: GeneralCard, // Video game root
  5: GeneralCard, // Video game
};

function ContentState({ loadingContent, contentState, content, error, contentError }) {
  const prevContentId = useRef();
  const contentId = useMemo(() => {
    if (loadingContent) {
      return prevContentId.current;
    }
    const contentId = get(contentState, 'content_id');
    prevContentId.current = contentId;
    return contentId;
  }, [loadingContent, contentState]);

  const ContentComponent = useMemo(() => {
    if (loadingContent) {
      return GeneralCard;
    }
    const contentType = get(content, 'type');
    return CONTENT_TYPE_MAP[contentType] || GeneralCard;
  }, [loadingContent, content]);

  return (
    <ContentStateContainer>
      {!error && !contentError ? (
        <>
          <StyledText size={18}>Content ID</StyledText>
          <StyledText size={18}>{contentId}</StyledText>
        </>
      ) : null}
      {!loadingContent && contentState ? (
        <CardContainer>
          <PrettyJson useLightTheme id='content-state' data={contentState} />
        </CardContainer>
      ) : null}
      {!loadingContent && (error || contentError) ? (
        <CardContainer>
          <UppercaseText size={14}>{DEFAULT_ERROR_MESSAGE}</UppercaseText>
        </CardContainer>
      ) : null}
      {contentId ? <ContentComponent content={content} contentId={contentId} /> : null}
    </ContentStateContainer>
  );
}

ContentState.propTypes = {
  loadingContent: PropTypes.bool,
  contentState: PropTypes.object,
  error: PropTypes.any,
  contentError: PropTypes.any,
};

export default ContentState;
