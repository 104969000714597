import _ from 'lodash';

const {
  REACT_APP_DEFAULT_IP_ADDRESS,
  REACT_APP_AUTH0_CALLBACKURL,
  REACT_APP_AUTH0_CLIENTID,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_PLATFORM_SERVER_URL,
  REACT_APP_USE_STUB_DATA,
  REACT_APP_METADATA_ENABLED,
  REACT_APP_FEATURE_FLAG_STUB,
  REACT_APP_FEATURE_FLAG_ENABLED = false,
} = process.env;

const processBooleanConfigValue = (configValue, defaultValue = false) => {
  if (_.isString(configValue)) {
    return configValue === 'true';
  } else if (_.isBoolean(configValue)) {
    return configValue;
  }

  return defaultValue;
};

export const getStubIpAddress = () => {
  return REACT_APP_DEFAULT_IP_ADDRESS;
};

export const getAuthenticationServerUrl = () => {
  return REACT_APP_PLATFORM_SERVER_URL;
};

export const getAuth0Domain = () => {
  return REACT_APP_AUTH0_DOMAIN;
};

export const getAuth0ClientId = () => {
  return REACT_APP_AUTH0_CLIENTID;
};

export const getAuth0CallbackUrl = () => {
  return REACT_APP_AUTH0_CALLBACKURL;
};

export const getAuth0RedirectUrl = () => {
  return REACT_APP_AUTH0_CALLBACKURL;
};

export const isMetadataEnabled = () => {
  return processBooleanConfigValue(REACT_APP_METADATA_ENABLED, false);
};

export const getUseStubData = () => {
  return processBooleanConfigValue(REACT_APP_USE_STUB_DATA, false);
};

export const isFeatureFlagsEnabled = () => {
  return processBooleanConfigValue(REACT_APP_FEATURE_FLAG_ENABLED, false);
}

export const isFeatureFlagsStubEnabled = () => {
  return processBooleanConfigValue(REACT_APP_FEATURE_FLAG_STUB, false);
}
