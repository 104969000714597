import React from 'react';
import Styled from 'styled-components';

const TagContainer = Styled.div`
  padding: 10px 15px;
  margin: 0 10px 0 0;
  border-radius: 10px;
  background-color: #D8D8D8;
  border-color: 
`;

const TagText = Styled.span`
  font-family: 'Fira Sans', 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: ${(props) => props.size || 12}px;
`;

const Tag = ({ label }) => {
  return (
    <TagContainer>
      <TagText size={12}>{label}</TagText>
    </TagContainer>
  );
};

export default Tag;
