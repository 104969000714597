import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Text } from 'samba-ui';

const VerificationSuccess = ({ history }) => {
  return (
    <Box mt='72px'>
      <Box>
        <Heading fontSize={5} color='white'>
          Your email has been confirmed!
        </Heading>
        <Box textAlign='center'>
          <Box my={3}>
            <Text fontSize={1} color='white'>
              We will be reviewing your application within a business day.
            </Text>
          </Box>
          <Box my={3}>
            <Text fontSize={1} color='white'>
              No need to do anything else while we review your application!
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

VerificationSuccess.propTypes = {
  history: PropTypes.object,
};

export default VerificationSuccess;
