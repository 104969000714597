import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Cookies from 'js-cookie';
import { theme } from 'samba-ui';

import 'normalize.css';
import './index.css';

import App from './App';
import UnauthorizedApp from './routes/unauthorized';
import Auth0 from './services/auth0';

const accessToken = Cookies.get('accessToken');

ReactDOM.render(
  <Router>
    <ThemeProvider theme={theme.v2}>{accessToken ? <App /> : <UnauthorizedApp auth={new Auth0()} />}</ThemeProvider>
  </Router>,
  document.getElementById('root'),
);
