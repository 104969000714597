import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import Styled from 'styled-components';
import { useAsync } from 'react-async-hook';
import { format } from 'date-fns';

import { getContentState } from './actions';
import ContentState from './ContentState';
import RefreshSelection from './RefreshSelection';
import useInterval from '../../../hooks/useInterval';
import { HeaderContainer, UppercaseText } from '../../../components/Text';
import delay from '../../../utils/delay';

const LabelContainer = Styled(HeaderContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const RefreshDurationContainer = Styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContentStateContainer = ({ error, selectedDevice }) => {
  const [refreshInterval, setRefreshInterval] = useState('off');
  const [lastUpdated, setLastUpdated] = useState();
  const {
    loading: loadingContent,
    error: contentError,
    result,
    execute: fetchContentState,
  } = useAsync(async () => {
    if (!selectedDevice) {
      return;
    }
    const [contentState] = await Promise.all([getContentState(selectedDevice), delay(250)]);
    setLastUpdated(`Last updated: ${format(new Date(), 'hh:mm:ss a')}`);
    return contentState;
  }, [selectedDevice]);

  useInterval(fetchContentState, refreshInterval === 'off' ? null : refreshInterval * 1000);

  const { contentState, content } = useMemo(() => {
    if (loadingContent || !result) {
      return {
        contentState: {},
        content: {},
      };
    }

    const content = _.get(result, 'content', {});
    const contentState = _.omit(result, ['content']);

    return {
      content,
      contentState,
    };
  }, [result, loadingContent]);

  if (!selectedDevice) {
    return null;
  }

  return (
    <div>
      <LabelContainer>
        <UppercaseText size={18}>Content State</UppercaseText>
        <RefreshDurationContainer>
          <UppercaseText>{loadingContent ? 'Loading...' : lastUpdated}</UppercaseText>
          <RefreshSelection
            loading={loadingContent}
            refreshInterval={refreshInterval}
            onRefresh={fetchContentState}
            onClick={(newDuration) => {
              setRefreshInterval(newDuration);
            }}
          />
        </RefreshDurationContainer>
      </LabelContainer>
      <ContentState
        loadingContent={loadingContent}
        contentState={contentState}
        content={content}
        error={error}
        contentError={contentError}
      />
    </div>
  );
};

export default ContentStateContainer;
