import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Flex, Link as SambaLink, Text } from 'samba-ui';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useInterval from '../../hooks/useInterval';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const PasswordSuccess = ({ timeout = 5000 }) => {
  const [countDown, setCountDown] = useState(timeout / 1000);

  useInterval(() => {
    setCountDown(countDown - 1);
  }, 1000);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.replace('/');
    }, timeout);
    return () => {
      clearTimeout(timer);
    };
  }, [timeout]);

  return (
    <Box mt='72px'>
      <Heading fontSize={5} color='white'>
        Password set! Welcome to the Real Time Integrations Page
      </Heading>
      <Flex alignItems='center' justifyContent='center' mt={3}>
        <Text fontSize={1} color='white'>
          Please
        </Text>
        <StyledLink to='signin'>
          <SambaLink fontSize={1} color='primary' mx={1}>
            click here
          </SambaLink>
        </StyledLink>
        <Text fontSize={1} color='white'>
          if you are not redirected in {countDown} seconds
        </Text>
      </Flex>
    </Box>
  );
};

PasswordSuccess.propTypes = {
  timeout: PropTypes.number,
};

export default PasswordSuccess;
