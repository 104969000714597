import { apiGet } from '../rtcid-api/fetch';
import get from 'lodash/get';

const buildEndpoint = (endpoint, version = 'v1') => `metadata/proxy/${version}/${endpoint}`;

export const getContentById = async (contentId) => {
  const endpoint = buildEndpoint(`content/${contentId}`);
  const results = await apiGet(endpoint, {});
  return get(results, 'data', {});
};

export const getAdByContentId = async (contentId) => {
  const endpoint = buildEndpoint(`ad/${contentId}`, 'v2');
  const results = await apiGet(endpoint, {});
  return get(results, 'data.results', {});
};

export const getProgramByContentId = async (contentId) => {
  const endpoint = buildEndpoint(`program/${contentId}`, 'v2');
  const results = await apiGet(endpoint, {});
  return get(results, 'data.results', {});
};

export const getGenreTypes = async () => {
  const endpoint = buildEndpoint('type/genre', 'v2');
  const results = await apiGet(endpoint, {});
  return get(results, 'data.results', []);
};

export const getSeriesById = async (seriesId) => {
  const endpoint = buildEndpoint(`series/${seriesId}`, 'v1');
  const results = await apiGet(endpoint, {});
  return get(results, 'data', {});
};
