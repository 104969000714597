import React from 'react';
import { Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import './App.css';
import IntegrationsTestPage from './routes/authorized/IntegrationsTestPage';
import { UserContextProvider } from './utils/contexts/UserContext';
import MetadataApiContextProvider from './utils/contexts/metadata/MetadataApiContext';
import DevicesContextProvider from './utils/contexts/devices/DevicesContext';

function App() {
  return (
    <QueryParamProvider ReactRouterRoute={Route}>
      <UserContextProvider>
        <MetadataApiContextProvider>
          <DevicesContextProvider>
            <IntegrationsTestPage />
          </DevicesContextProvider>
        </MetadataApiContextProvider>
      </UserContextProvider>
    </QueryParamProvider>
  );
}

export default App;
