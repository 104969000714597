import React from 'react';
import Styled from 'styled-components';

import DeviceItem from './DeviceItem';
import { UppercaseText } from '../../../components/Text';

const EmptyContainer = Styled.div`
  padding: 20px;
`;

const EmptyDevice = () => {
  return (
    <DeviceItem center={false}>
      <EmptyContainer>
        <UppercaseText>No devices found</UppercaseText>
      </EmptyContainer>
    </DeviceItem>
  );
};

export default EmptyDevice;
