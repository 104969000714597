import React from 'react';
import { Box, Text, Heading, Link as SambaLink } from 'samba-ui';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const NoAssignedApplication = () => {
  return (
    <Box mt='72px' textAlign='center'>
      <Heading fontSize={5} color='white'>
        You are not authorized to see this page
      </Heading>
      <Box>
        <Text color='white' fontSize={2}>
          You can reach out Samba TV admins to request app permission
        </Text>
      </Box>
      <Box my={3}>
        <StyledLink to='/'>
          <SambaLink fontSize={2} fontWeight={500}>
            Go back to sign-in page
          </SambaLink>
        </StyledLink>
      </Box>
    </Box>
  );
};

export default NoAssignedApplication;
