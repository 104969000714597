import React, { useContext } from 'react';
import Styled from 'styled-components';

import Devices from './devices/Devices';
import IPAddress from './ipaddress/IPAddress';
import { UserContext } from '../../utils/contexts/UserContext';
import NoAccess from '../unauthorized/NoAccess';
import { DevicesContext } from '../../utils/contexts/devices/DevicesContext';

const Background = Styled.div`
  background-color: black;
  color: white;
  padding: 15px;
`;

const IntegrationsTestPage = () => {
  const { loading: profileIsLoading, error: profileError, hasPermission, isSambaEmployee } = useContext(UserContext);
  const { availableDevices, loading, error, ipAddress, selectedDevice, onSelectDevice } = useContext(DevicesContext);

  if (!hasPermission && !isSambaEmployee) {
    return <NoAccess />;
  }

  return (
    <Background>
      <IPAddress ipAddress={ipAddress} loading={loading || profileIsLoading} />
      <Devices
        devices={availableDevices}
        loading={loading || profileIsLoading}
        error={error || profileError}
        selectedDevice={selectedDevice}
        onSelectDevice={onSelectDevice}
      />
    </Background>
  );
};

export default IntegrationsTestPage;
