import auth0JS from 'auth0-js';
import { getAuth0Domain, getAuth0ClientId, getAuth0CallbackUrl, getAuth0RedirectUrl } from './config';

class Auth {
  auth0 = new auth0JS.WebAuth({
    domain: getAuth0Domain(),
    clientID: getAuth0ClientId(),
    callbackUrl: getAuth0CallbackUrl(),
    audience: 'https://api.platform.samba.tv',
    redirectUri: getAuth0RedirectUrl(),
    responseType: 'token id_token',
    realm: 'Username-Password-Authentication',
    scope: 'openid profile',
  });

  authorize() {
    this.auth0.authorize({ prompt: 'login', connection: 'google-oauth2' });
  }

  login(body, callback) {
    this.auth0.login(body, callback);
  }

  handleAuthentication(callback) {
    this.auth0.parseHash(callback);
  }

  getTokenSilently() {
    return this.auth0.getTokenSilently();
  }
}

export default Auth;
