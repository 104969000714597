import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Logo } from 'samba-ui';
import { Switch, Route, Redirect } from 'react-router-dom';

import SignIn from './SignIn';
import SignUp from './SignUp';
import Thanks from './Thanks';
import SetPassword from './SetPassword';
import ForgotPassword from './ForgotPassword';
import ForgotPasswordSuccess from './ForgotPasswordSuccess';
import { setAccessToken } from '../../utils/accessToken';
import VerificationSuccess from './VerificationSuccess';
import ResetPassword from './ResetPassword';
import PasswordSuccess from './PasswordSuccess';
import AuthError from './AuthError';
import LoadingBox from './LoadingBox';
import { resetPassword, register } from '../../services/authentication';
import { DARK_BG_COLOR } from '../../utils/cssConstants';

const UnauthorizedApp = ({ auth }) => {
  const [error, setError] = useState(null);
  const handleError = (message) => {
    setError(message);
  };
  const signup = async (body, cb) => {
    const res = await register(body);

    if (res.status === 200 || res.status === 201) {
      window.location.replace(`/thanks?email=${encodeURIComponent(body.email)}`);
      cb();
    } else if (res.status === 400 || res.status === 500) {
      cb('Registration is failed! Please try again later!');
    }
  };

  const onLogin = (body, cb) => {
    auth.login(body, cb);
  };

  const authorize = () => {
    auth.authorize();
  };

  const handleAuthentication = (cb) => {
    auth.handleAuthentication((err, authResult) => {
      if (err) {
        setError(err.errorDescription);
        return cb(err.errorDescription);
      }
      if (authResult && authResult.accessToken && authResult.idToken) {
        setAccessToken(authResult.accessToken);
        return cb();
      }
    });
  };

  const setPassword = async (body) => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    try {
      const res = await resetPassword(body, code);
      if (res.status === 200) {
        return window.location.replace('/success');
      }
      setError('Error happened!');
    } catch (error) {
      setError('Error happened!');
    }
  };

  const onResetPassword = async (body) => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    try {
      const res = await resetPassword(body, code);
      if (res.status === 200) {
        return window.location.replace('/success');
      }
      setError('Error happened!');
    } catch (error) {
      setError('Error happened!');
    }
  };

  return (
    <Box backgroundColor={DARK_BG_COLOR} height='100vh'>
      <Flex justifyContent='center' pt={'86px'} pb={'40px'}>
        <Logo.WithText color='white' height={56} width={190} />
      </Flex>
      <Flex justifyContent='center'>
        <Switch>
          <Route path='/' exact render={() => <SignIn onClick={onLogin} onSambaLoginClick={authorize} />} />
          <Route
            path='/signup'
            exact
            render={() => <SignUp onClick={signup} onSambaLoginClick={authorize} onError={handleError} />}
          />
          <Route path='/thanks' exact render={() => <Thanks />} />
          <Route
            path='/createpassword'
            exact
            render={() => <SetPassword onClick={setPassword} onError={handleError} />}
          />
          <Route path='/forgotpassword' exact render={() => <ForgotPassword />} />
          <Route path='/forgotpasswordsuccess' exact render={() => <ForgotPasswordSuccess />} />
          <Route
            path='/changepassword'
            exact
            render={() => <ResetPassword onClick={onResetPassword} onError={handleError} />}
          />
          <Route path='/verificationsuccess' exact render={(p) => <VerificationSuccess {...p} />} />
          <Route path='/success' exact render={() => <PasswordSuccess />} />
          <Route
            path='/callback'
            render={(p) => {
              handleAuthentication((err) => {
                return err ? p.history.push('/error') : window.location.replace(window.location.origin);
              });
              return <LoadingBox />;
            }}
          />
          <Route path='/error' exact render={() => <AuthError error={error} />} />
          <Route component={() => <Redirect to='/' />} />
        </Switch>
      </Flex>
    </Box>
  );
};

UnauthorizedApp.propTypes = {
  auth: PropTypes.shape({
    authorize: PropTypes.func,
    handleAuthentication: PropTypes.func,
  }),
};

export default UnauthorizedApp;
