import size from 'lodash/size';
import React from 'react';
import Styled from 'styled-components';

import { StyledText } from '../../../../../components/Text';
import { MAX_SHARED_DEVICES } from '../../../../../utils/constants';

const GuidContainer = Styled.div`
  align-self: center;
  text-align: center;
`;

const ListText = Styled(StyledText)`
   margin-block-start: 0;
   margin-block-end: 0;
`;

const IpsList = Styled.ul`
  display: flex;
  flex-direction: column;
  margin-block-start: inherit;
  margin-block-end: inherit;
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
  padding: 0 10px;
`;

const IpRow = Styled.li`
  padding: 0 10px;  
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid black;
  padding: 5px 0 10px 0;
`;

const SharedDevicesList = ({ deviceId, externalAccess }) => {
  return (
    <>
      {externalAccess && size(externalAccess) >= MAX_SHARED_DEVICES ? (
        <StyledText size={14} color={'red'}>
          You have reached the maximum external IP's shared for this device
        </StyledText>
      ) : null}
      <StyledText size={14} color={'black'}>
        These are the external IP's that can access the device with the ID ending in...
      </StyledText>
      <GuidContainer>
        {deviceId ? (
          <StyledText color={'black'} size={22}>
            ...{deviceId.slice(-8)}
          </StyledText>
        ) : null}
      </GuidContainer>
      <IpsList>
        <IpRow>
          <ListText>IP</ListText>
          <ListText>Expires At</ListText>
        </IpRow>
        {externalAccess.map((access) => {
          return (
            <IpRow key={access.external_ip}>
              <ListText size={18}>{access.external_ip}</ListText>
              <ListText size={18}>{access.expirationText}</ListText>
            </IpRow>
          );
        })}
      </IpsList>
    </>
  );
};

export default SharedDevicesList;
