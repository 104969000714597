import size from 'lodash/size';
import React from 'react';
import { ButtonContainer, ButtonText } from '../styles';

const SharedButton = ({ disabled, onClick, externalAccess }) => {
  return (
    <ButtonContainer disabled={disabled} onClick={onClick}>
      <ButtonText color={'white'} size={12}>
        Shared with {size(externalAccess)} networks
      </ButtonText>
    </ButtonContainer>
  );
};

export default SharedButton;
