import get from 'lodash/get';
import size from 'lodash/size';
import React from 'react';
import ShareButton from './ShareButton';
import SharedButton from './SharedButton';
import ExternalSharedButton from './ExternalSharedButton';

const Share = ({ isSelected, externalAccess, isExternalDevice, onClickShare, onClickShared }) => {
  if (!isSelected) {
    return null;
  }

  if (isExternalDevice) {
    return <ExternalSharedButton />;
  }
  if (!externalAccess || !size(externalAccess)) {
    return <ShareButton onClick={onClickShare} />;
  }

  return (
    <SharedButton
      disabled={isExternalDevice}
      onClick={onClickShared}
      externalAccess={externalAccess}
      expiration={get(externalAccess, 'expires_at')}
    />
  );
};

export default Share;
