import _ from 'lodash';

export const ONE_HOUR = 60 * 60;
export const expiresInValues = {
  FOUR_HOURS: {
    duration: ONE_HOUR * 4,
    label: '4 hours',
    value: 'FOUR_HOURS',
  },
  TWELVE_HOURS: {
    duration: ONE_HOUR * 12,
    label: '12 hours',
    value: 'TWELVE_HOURS',
  },
  TWENTY_FOUR_HOURS: {
    duration: ONE_HOUR * 24,
    label: '24 hours',
    value: 'TWENTY_FOUR_HOURS',
  },
};
export const expiresInOptions = _.valuesIn(expiresInValues);
