import stubDevices from './stubs/devices';
import stubDeviceInfo from './stubs/deviceInfo';
import stubContentState from './stubs/contentState';

export const getDevices = async () => {
  return stubDevices;
};

export const getDeviceInfo = async () => {
  return stubDeviceInfo;
};

export const getDeviceContentState = async () => {
  return stubContentState;
};
