const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const E164_PHONE_NUMBER = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export function validateEmail(email) {
  return EMAIL_REGEX.test(String(email).toLowerCase());
}

export function validatePhone(phone) {
  // TODO: for V1 we decided to validate only E18 format (Auth0 required format).
  // Possible moving to Twillio API
  return E164_PHONE_NUMBER.test(phone);
}
