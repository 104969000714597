import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import trim from 'lodash/trim';
import getFeatureFlagService from "../../../services/feature-flags/factory";
import getApiServices from '../../../services/rtcid-api/factory';
import {isFeatureFlagsStubEnabled} from "../../../services/config";

const isFeatureFlagStubEnabled = isFeatureFlagsStubEnabled();
const { getDeviceVariants: getFeatures } = getFeatureFlagService(isFeatureFlagStubEnabled);
const { getDevices } = getApiServices();

export const getDeviceVariants = async (deviceId, flingoMake) => {
  const results = await getFeatures(deviceId, flingoMake);
  const error = get(results, 'errors');
  const data = get(results, 'data.data');

  return {
    data,
    error,
  }
};

export const getDevicesAndIpAddress = async (defaultDeviceId, setDeviceId) => {
  try {
    const results = await getDevices();
    const ipAddress = get(results, 'yourip', '');

    // Is the selected device found?
    //  If it is, move it to the front of the line
    let devices = get(results, 'devices', []);
    const deviceIndex = findIndex(devices, (device) => device.guid === defaultDeviceId);
    if (deviceIndex >= 0) {
      devices = [devices[deviceIndex], ...devices.slice(0, deviceIndex), ...devices.slice(deviceIndex + 1)];
    } else {
      setDeviceId(null);
    }

    return {
      devices,
      ipAddress: trim(ipAddress),
      error: false,
    };
  } catch (err) {
    // TODO: Log somewhere?
    return {
      devices: [],
      ipAddress: '',
      error: 'Unable to load device information',
    };
  }
};
