import { useEffect, useRef } from 'react';

export default function useTimer(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null && delay > 0) {
      const id = setTimeout(tick, delay);
      return () => clearTimeout(id);
    }
  }, [savedCallback, delay]);
}
