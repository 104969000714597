import { textDark, textLight } from '../../utils/cssConstants';

export const darkTheme = {
  base00: 'rgba(0, 0, 0, 0)',
  base01: textLight,
  base02: 'rgba(0, 0, 0, 0)',
  base03: textLight,
  base04: textLight,
  base05: textLight,
  base06: textLight,
  base07: textLight,
  base08: textLight,
  base09: textLight,
  base0A: textLight,
  base0B: textLight,
  base0C: textLight,
  base0D: textLight,
  base0E: textLight,
  base0F: textLight,
};

export const lightTheme = {
  base00: 'rgba(0, 0, 0, 0)',
  base01: textDark,
  base02: 'rgba(0, 0, 0, 0)',
  base03: textDark,
  base04: textDark,
  base05: textDark,
  base06: textDark,
  base07: textDark,
  base08: textDark,
  base09: textDark,
  base0A: textDark,
  base0B: textDark,
  base0C: textDark,
  base0D: textDark,
  base0E: textDark,
  base0F: textDark,
};
