import _ from 'lodash';
import React, { useState, createContext, useMemo } from 'react';
import { useAsync } from 'react-async-hook';

import { getGenreTypes } from './actions';

export const MetadataApiContext = createContext();

export const MetadataApiContextProvider = (props) => {
  const [genres, setGenres] = useState();
  const { loading, error } = useAsync(async () => {
    const genresTypes = await getGenreTypes();
    setGenres(genresTypes);
  }, []);

  const genresById = useMemo(() => {
    if (loading || !genres) {
      return {};
    }

    return _.keyBy(genres, 'genre_id');
  }, [loading, genres]);

  return (
    <MetadataApiContext.Provider value={{ genres, genresById, loading, error }}>
      {props.children}
    </MetadataApiContext.Provider>
  );
};

export default MetadataApiContextProvider;
