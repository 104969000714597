import get from 'lodash/get';
import { graphqlFetch, graphqlMutation } from "./fetch";

const getFeatureTogglesQuery = `query GetFeatureToggles($input: FeatureTogglesInput!) {
  featureToggles(input: $input) {
    name
    currentVariant
    variants {
      name
    }
  }
}`
export const getDeviceVariants = async (deviceId, flingoMake) => {
  return graphqlFetch(getFeatureTogglesQuery, { input: { guid: deviceId, flingoMake } });
}

const mutateFeatureToggleQuery = `mutation SetFeatureToggle($input: SetFeatureToggleInput!) {
  setFeatureToggle(input: $input) {
    currentVariant
  }
}`;
export const setDeviceVariant = async (deviceId, featureVariant, featureName) => {
  const result = await graphqlMutation(mutateFeatureToggleQuery, { input: { deviceGuid: deviceId, featureName, featureVariant, }});
  return get(result, 'data');
}
