import get from 'lodash/get';
import has from 'lodash/has';
import size from 'lodash/size';
import getApiServices from '../../../services/rtcid-api/factory';
import { getContentById, getAdByContentId, getSeriesById } from '../../../services/metadata-api/api';
import { isMetadataEnabled } from '../../../services/config';
import { CONTENT_TYPES } from '../../../utils/constants';

const { getDeviceContentState } = getApiServices();

// const DAILY_SHOW = '14400000135949';
// const QUIET_PLACE = '1133938210522';
// const DEXTER = '2233445147349';
// const ADVERTISEMENT = '4432470461574';
// const SUPER_BOWL = '2233447358544';
// const EVERGREEN_1 = '5ebee2905ef8d494e733972a';

export const getContentState = async (deviceId) => {
  let deviceContent = await getDeviceContentState(deviceId);
  // deviceContent = { ...deviceContent, content_id: DAILY_SHOW };

  if (isMetadataEnabled()) {
    let contentId = get(deviceContent, 'content_id');
    if (contentId) {
      // Can we look up the information? Sometimes we get ids that we won't have enough context to map
      if (contentId.match(/^[0-9]+$/)) {
        const contentDetails = await getContentDetails(contentId);
        const hasImage = has(contentDetails, 'images') && size(contentDetails.images) > 0;
        if (get(contentDetails, 'series_id') && !hasImage) {
          // Fetch the series and attach to the content
          const series = await getSeriesById(contentDetails.series_id);
          contentDetails.series = series;
        }
        deviceContent.content = contentDetails;
      }
    }
  }

  return deviceContent;
};

export const getContentDetails = async (contentId) => {
  // We can get everything bug advertisements from the /v1/content endpoint
  let content = await getContentById(contentId);
  if (!content || !has(content, 'content_id')) {
    // If we don't get a result, assume it's an ad
    content = await getAdByContentId(contentId);
    if (!content) {
      return;
    }
    return { ...content, type: CONTENT_TYPES.ADVERTISEMENT };
  }
  return content;
};
