import React from 'react';

import DeviceItem from './DeviceItem';

const EmptyDevice = () => {
  return (
    <DeviceItem>
      <p></p>
    </DeviceItem>
  );
};

export default EmptyDevice;
