import Promise from 'bluebird';
import castArray from 'lodash/castArray';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import size from 'lodash/size';
import React, { useState, createContext, useMemo, useCallback, useEffect } from 'react';
import { useAsync } from 'react-async-hook';

import { getDevicesAndIpAddress, getDeviceVariants as getDeviceVariantsService } from './actions';
import useRouterState from '../../../hooks/useRouterState';
import {isFeatureFlagsEnabled} from "../../../services/config";

export const DevicesContext = createContext();

const transformVariant = (variant) => ({ ...variant, label: variant.name, value: variant.name });

export const DevicesContextProvider = (props) => {
  const [devices, setDevices] = useState([]);
  const { deviceId, setDeviceId } = useRouterState();
  const [filterExternal, setFilterExternal] = useState(false);

  const { loading, result, error } = useAsync(async () => {
    const results = await getDevicesAndIpAddress(deviceId, setDeviceId);
    const devices = get(results, 'devices', []);

    let featureResults = [];
    if (isFeatureFlagsEnabled()) {
      featureResults = await Promise.all(devices.map(device => {
        return getDeviceVariantsService(device.guid, device.flingo_make);
      }));
    }

    devices.forEach((device, i) => {
      const featureResult = get(featureResults, `[${i}]`, {});
      device.variants = get(featureResult, 'data.featureToggles[0].variants', []).map(transformVariant);
      const currentVariantName = get(featureResult, 'data.featureToggles[0].currentVariant');
      const variantName = get(featureResult, 'data.featureToggles[0].name');
      const selectedVariant = find(device.variants, (variant) => {
        return variant.name === currentVariantName;
      });
      device.variantName = variantName;
      device.selectedVariant = selectedVariant && transformVariant(selectedVariant);
    })

    setDevices(devices);
    return results;
  }, []);

  const { ipAddress, apiError } = useMemo(() => {
    return {
      ipAddress: get(result, 'ipAddress', ''),
      apiError: error || get(result, 'error', false),
    };
  }, [result, error]);

  const onSelectDevice = useCallback(
    (device) => {
      setDeviceId(device.guid);
    },
    [setDeviceId],
  );

  const onToggleFilterExternal = useCallback(() => {
    setFilterExternal(!filterExternal);
  }, [filterExternal]);

  const availableDevices = useMemo(() => {
    if (loading) {
      return [];
    }

    if (filterExternal) {
      return devices.filter((device) => {
        const externalAccess = get(device, 'external_access', []);
        return externalAccess && isArray(externalAccess) && size(externalAccess);
      });
    }
    return devices;
  }, [filterExternal, devices, loading]);

  useEffect(() => {
    if (loading || deviceId) {
      return;
    }

    if (availableDevices && availableDevices.length === 1) {
      setDeviceId(availableDevices[0].guid);
    }
  }, [deviceId, availableDevices, loading, setDeviceId]);

  const onDeviceShareExpires = useCallback(
    (device) => {
      if (!devices) {
        return;
      }

      const index = findIndex(devices, (d) => d.guid === device.guid);
      if (index >= 0) {
        setDevices([...devices.slice(0, index), ...devices.slice(index + 1)]);
      }

      if (deviceId && deviceId === device.guid) {
        setDeviceId();
      }
    },
    [devices, deviceId, setDeviceId],
  );

  const addExternalAccess = useCallback(
    (device, externalAccess) => {
      const index = findIndex(devices, (d) => d.guid === device.guid);
      if (index >= 0) {
        const device = devices[index];
        const prevExternalAccess = get(device, 'external_access', []);
        setDevices([
          ...devices.slice(0, index),
          { ...device, external_access: [...castArray(prevExternalAccess), externalAccess] },
          ...devices.slice(index + 1),
        ]);
      }
    },
    [devices],
  );

  const onSelectDeviceVariant = useCallback((device, variant, variantName) => {
    const index = findIndex(devices, (d) => d.guid === device.guid);
    if (index >= 0) {
      const device = devices[index];
      setDevices([
        ...devices.slice(0, index),
        { ...device, selectedVariant: variant },
        ...devices.slice(index + 1),
      ]);
    }
  }, [devices])

  const getDeviceVariants = useCallback((device) => {
    return get(device, 'variants', []);
  }, []);

  return (
    <DevicesContext.Provider
      value={{
        devices,
        availableDevices,
        loading,
        error: apiError,
        selectedDevice: deviceId,
        addExternalAccess,
        onSelectDevice,
        onToggleFilterExternal,
        onDeviceShareExpires,
        getDeviceVariants,
        onSelectDeviceVariant,
        filterExternal,
        ipAddress,
      }}
    >
      {props.children}
    </DevicesContext.Provider>
  );
};

export default DevicesContextProvider;
