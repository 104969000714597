import _ from 'lodash';
import { format } from 'date-fns';

import { apiDelete, apiGet, apiPost } from './fetch';
import { getDevicesFromResponse, getDeviceInfoFromResponse, getDeviceContentStateFromResponse } from './utils';

export const getDevices = async (ipAddress) => {
  const response = await apiGet('devices', {}, ipAddress);
  const deviceResults = getDevicesFromResponse(response);

  return {
    ...deviceResults,
    devices: _.get(deviceResults, 'devices', []).map((device) => {
      return {
        ...device,
        external_access: _.map(_.get(device, 'external_access', {}), (externalAccess) => {
          const expiration = _.get(externalAccess, 'expires_at');
          const expirationText = expiration ? format(new Date(expiration * 1000), 'p') || '' : '';
          return {
            ...externalAccess,
            expirationText,
            ipAddress: _.get(externalAccess, 'external_ip'),
          };
        }),
      };
    }),
  };
};

export const getDeviceInfo = async (deviceId) => {
  const deviceContent = await apiGet('devices', { metadata_types: 'device_info', sdids: deviceId });
  return getDeviceInfoFromResponse(deviceContent, deviceId);
};

export const getDeviceContentState = async (deviceId) => {
  const deviceContent = await apiGet('devices', { metadata_types: 'cid_state', sdids: deviceId });
  return getDeviceContentStateFromResponse(deviceContent, deviceId);
};

export const postExternalAccess = async (ipAddress, guid, ttlSeconds) => {
  const results = await apiPost('external_access', { external_ip: ipAddress, sdid: guid, ttl_seconds: ttlSeconds });
  const error = _.get(results, 'data.error', false);
  if (error) {
    return {
      error: error || true,
      data: false,
    };
  }

  const data = _.get(results, 'data', {});
  const expiration = _.get(data, 'expires_at');
  const expirationText = expiration ? format(new Date(expiration * 1000), 'p') || '' : '';
  return {
    data: {
      ...data,
      external_ip: ipAddress,
      expirationText,
    },
    error: false,
  };
};

export const deleteExternalAccess = async (guid) => {
  return apiDelete('external_access', { sdid: guid });
};
